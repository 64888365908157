import React, {useEffect} from 'react';
import guidetwo from "assets/images/webinar-marketing/guidetwoa.png";

const GuideTwoForm = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src='https://js-eu1.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
          if (window.hbspt) {
              window.hbspt.forms.create({
                  region: "eu1",
                  portalId: '25087743',
                  formId: "3fab7853-ffe7-4a9a-92bd-18bd55cb4ed3",
                  target: '#hubspotForm'
              });
          }
      });
  
  }, []);

return (
  <div>
      <div id="hubspotForm"></div>
  </div>
  )
};

const  GuideTwo = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>GUIDE</h5>
                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">How to stop living from payday to payday: Your guide to financial freedom</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded '>
                    <img src={guidetwo} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>Work, wait for payday, pay bills, rinse and repeat. Does this routine sound familiar? Studies show that one in two people live from payday to payday.
                    <br /> <br />
                    Read this comprehensive guide and learn how to break out of the payday cycle, live a debt-free life and get on the path to financial freedom. </div>

                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6 bg-ep-yellow" style={{"minHeight":"500px"}}>
                  <h2 className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Download Your Guide Now</h2>
                  <GuideTwoForm
                      region='eu1'
                      portalId= '25087743'
                      formId='cb7311d5-20fd-4d98-a53b-dbd063b448f7'
                    />
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default GuideTwo

