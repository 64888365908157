import React, { useEffect } from 'react';
import webinarseven from "assets/images/webinar-marketing/webinar-seven-yemi.jpg";

const WebinarSevenForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        // script.src = 'https://js-eu1.hsforms.net/forms/shell.js';
        script.src = 'https://js-eu1.hsforms.net/forms/v2.js';

        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "865d75e6-efe1-4ffb-ac9b-d01f38aaad1e",
                    target: '#hubspotForm',
                    version: "V2_PRERELEASE"
                });
            }
        });

    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
};

const WebinarSeven = () => {
    return (

        <div className=" bg-white overflow-x-hidden">
            <div>
                <div className="container marketing-container">
                    <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
                        <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                            <div className="mb-4 pt-4">
                                <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                                <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">Improving employee experience through financial wellness</div>
                            </div>
                            <div className='mx-auto caption rounded'>
                                <div className='pb-3 lg:pr-14 rounded'>
                                    <img src={webinarseven} alt="mareting " className='w-full h-full object-contain rounded' />
                                </div>
                                <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                                    <div className='py-3'>
                                        Companies today can’t get by with just offering basic benefits like Pension and health insurance. They must go above and beyond and provide employees with an exemplary experience in the workplace.
                                        <br /> <br />
                                        The employee experience is the sum of all the interactions your employees have with your company. It involves the culture, benefits, physical work environment, and tools you provide for employee success and can be linked to the company’s bottom line.
                                        <br /> <br />
                                         Join our webinar with Chief Talent Officer and HR Professional <span className='font-semibold'>Yemi Faseun,</span> as he discusses ‘Improving Employee Experience’.  The event will give you the knowledge you need to implement effective employee experience management strategies in your workplace.<span className='font-normal'> We look forward to seeing you there!</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='py-8 lg:w-2/5 rounded' >
                            <div className="font-work-sans px-8  py-6" style={{ "minHeight": "500px", "border": "1px solid #9CA3AF" }}>
                                <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                                <WebinarSevenForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WebinarSeven;
