import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const faqsCategories = [
  {
    title: "About Earnipay",
    description:
      "All you need to know about Earnipay - our current products and processes.",
  },
  {
    title: "Earnipay Flexible Salary Access",
    description:
      "Here, we’ve answered questions about accessing your earned salary with Earnipay.",
  },
  {
    title: "Getting started with Earnipay",
    description:
      "Using Earnipay is very easy, let’s show you how.",
  },
  {
    title: "Earnipay Dashboard",
    description:
      "More information on how to use your dashboard.",
  },
  {
    title: "Earnings Payment",
    description:
      "How payment works on Earnipay.",
  },
  {
    title: "Salaries",
    description:
      "We’ve answered your questions on how Earnipay affects your salaries.",
  },
  {
    title: "Channels",
    description:
      "Questions about using our mobile, web and USSD channels.",
  },
  {
    title: "Support",
    description:
      "Need to reach us?",
  },
];

export const faqs = [
  {
    question: "What is Earnipay",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Earnipay promotes the financial health and wellbeing of employees in
          Africa. We provide employees with financial tools to enable them to
          make better financial decisions and achieve financial freedom. The
          tools we provide are
          <span className="font-bold pl-2">
            flexible salary access, financial education
          </span>
          .
        </p>

        <p className="py-2">
          Earnipay is offered to employees in partnership with their employers.
          Meaning, employees can only access Earnipay if their employer onboards
          them on Earnipay. The employers&nbsp;
          <a href="https://earnipay.com/" className="font-bold text-blue-500 text-underline">
            signup
          </a>&nbsp;on Earnipay and add their employees in a simple process and
          immediately, their employees have access to all of our financial
          tools.
        </p>
        <p className="py-2">
          We are a workplace benefit. This means Earnipay is a financial benefit
          employers offer employees. Employees in Africa are experiencing a
          financial health pandemic. To come out of this pandemic, employers
          need to play an active role in promoting our financial health and
          helping us pay attention to it. Employers sign up to Earnipay because
          they care about their Employees' financial health and want to ensure
          they don't worry about the number one stress factor "Money".
        </p>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "What is Flexible Salary Access (FSA)?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Flexible Salary Access is one of our financial tools. FSA transforms
          the way employees are paid. It uses technology developed by us that
          lets employees access a percentage of their earned salary via an
          innovative
          <span className="font-bold"> earn-as-you-work model.</span>.
        </p>

        <p>
          Earned Salaries are salaries accrued based on the number of days
          worked. An employee with a monthly net salary of N150,000 in a 30 days
          month would earn N5,000 per day. In five (5) days, the employee would
          accrue N25,000. With Earnipay, the employee can access 50% of their
          earned salary by the 5th day and not need to wait till the end of the
          month or borrow to afford daily expenses and urgent payments
        </p>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "What are the benefits of using Earnipay for employees?",
    answer: (
      <div className="faq-answer">
        <ol className="list-decimal">
          <li>Financial safety net to cover expenses and urgent payments.</li>
          <li>Take advantage of investment opportunities with flexible pay</li>
          <li>
            Avoid unnecessary borrowing and being a target for predatory payday
            lenders.
          </li>
          <li>
            Access earned salary on the go via <a href="https://earnipay.com/" className="text-blue-500">
              web app
            </a>,  mobile app (<a
              href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
              className="text-blue-500 pl-2"
            >
              Playstore
            </a> or  <a
              href="https://apps.apple.com/ng/app/earnipay/id1582293975"
              className="text-blue-500 pr-2"
            >
              App Store
            </a>) and/or USSD - *347*729#.
          </li>
          <li>Financial literacy to build good spending and saving habits. </li>
        </ol>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "What are the benefits of using Earnipay for employers?",
    answer: (
      <div className="faq-answer">
        <ol className="list-decimal">
          <li>
            Productivity: Boost employee productivity and motivation by helping
            them access financial wellness products - Flexible salary access,
            Budgeting and Tracking, Financial Education and Savings.
          </li>
          <li>
            Cashflow support: No pressure on business cash flow to fund salary
            advances because employees can now access their earned salaries at
            any time before payday.
          </li>
          <li>
            Talent: As the world of work changes, Earnipay offers employers the
            ability to attract and retain top talent by providing the number one
            Benefit employees truly deserve.
          </li>
          <li>People-centered: Have a reputation as an employee-centric company that cares about their employees</li>
        </ol>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "How much does it cost to use Earnipay?",
    answer: (
      <div className="faq-answer">
        <p className="">
          <span className="font-bold">Employers:</span> Earnipay is FREE for
          employers. There are no registration fees, maintenance fees or any
          hidden charges. It is completely free.
        </p>
        <p className="">
          <span className="font-bold">Employees:</span> Our financial tools -
          Budgeting, Savings and Financial Education are FREE. For our financial
          tool -Flexible Salary Access- employees can access their earned
          salaries for a very small fee.
        </p>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "How can Employers join and use Earnipay?",
    answer: (
      <div className="faq-answer">
        <p>
          <span className="font-bold">Employers:</span> Employers are required to
          provide the&nbsp;
          <span className="font-bold">
            company name and address, registration number, total number of
            employees, Tax Identification Number (TIN), total net monthly
            payroll.
          </span>&nbsp;
        </p>
        <p>
          <span className="font-bold">Verified and Onboarded:</span> The
          information required will be verified and account onboarded.
        </p>
        <p>
          <span className="font-bold">Add New employees:</span> Upload your employees’ information by clicking&nbsp;
          <span className="font-bold">Add New Employees </span>- an automated
          email and SMS is sent inviting them to activate their account. While
          at this, you can group your employees and even set different
          withdrawal limits and allow auto-withdrawal for each group.
        </p>
        <p>
          <span className="font-bold">Manage:</span> You can track/view
          withdrawals, repay withdrawal earnings, manage and export your
          payroll.
        </p>
      </div>
    ),

    category: "About Earnipay",
  },

  {
    question: "How can Employees join and use Earnipay?",
    answer: (
      <div className="faq-answer">
        <ul className="list-disc">
          <li>
            <span className="font-bold">Invite Email/SMS:</span> Employees
            receive an Invite email and SMS.
          </li>
          <li>
            <span className="font-bold">Activate:</span> Employees activate
            their accounts by using the&nbsp;
            <a href="https://earnipay.com/" className="text-blue-500">
              web app
            </a>
            , mobile app on
            <a
              href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
              className="text-blue-500 pl-2"
            >
              Playstore
            </a>&nbsp;
            or&nbsp;
            <a
              href="https://apps.apple.com/ng/app/earnipay/id1582293975"
              className="text-blue-500 pr-2"
            >
              App Store
            </a>
            or *347*729# and setting their withdrawal pins.
          </li>
          <li>
            <span className="font-bold">Withdraw:</span> Employees can withdraw up to 50% of their earned salaries. What is accessible per time will be shown on the employee portal. You are allowed a minimum of N2,000
            and a maximum of N50,000 per withdrawal. This means a withdrawal of 100,000 can be done in two transactions just like an ATM.
          </li>
        </ul>
      </div>
    ),

    category: "About Earnipay",
  },
  {
    question: "How much of my earned salary can I access?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          You can access up to 50% of your earned salaries. If you earn a
          monthly net salary of N150,000 in a 30 days month you would earn
          N5,000 per day. In five (5) days,you would have earned N25,000. With
          Earnipay, you can access 50% of their earned salary by the 5th day and
          not need to wait till the end of the month or borrow to afford daily
          expenses and urgent payments.
        </p>
      </div>
    ),

    category: "Earnipay Flexible Salary Access",
  },
  {
    question: "How much does it cost to access my earned salaries?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Each withdrawal of earned salaries attracts a very small fee. <br />
          Here are our different fees and withdrawal bands: <br /><br />
          <table className="faq-table-price">
            <thead>
              <tr>
                <th colSpan={2}>
                  Range
                </th>
                <th>
                  Fee
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  ₦500.00
                </td>
                <td>
                  ₦1,000.00
                </td>
                <td>
                  ₦55.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦1,000.10
                </td>
                <td>
                  ₦2,000.00
                </td>
                <td>
                  ₦110.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦2000.10
                </td>
                <td>
                  ₦5,000.00
                </td>
                <td>
                  ₦200.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦5,000.10
                </td>
                <td>
                  ₦10,000.00
                </td>
                <td>
                  ₦500.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦10,000.10
                </td>
                <td>
                  ₦25,000.00
                </td>
                <td>
                  ₦750.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦25,000.10
                </td>
                <td>
                  ₦40,000.00
                </td>
                <td>
                  ₦1,000.00
                </td>
              </tr>
              
              <tr>
                <td>
                  ₦40,000.10
                </td>
                <td>
                  ₦50,000.00
                </td>
                <td>
                  ₦1,250.00
                </td>
              </tr>
              
            </tbody>
          </table>
          <br />
          There are no interest rates, no hidden charges, just the stated fees.
        </p>
      </div>
    ),

    category: "Earnipay Flexible Salary Access",
  },
  {
    question: "How many times can I withdraw my earned salary in a month?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          You can withdraw your earnings multiple times in a month. As long as
          you have earned it, you can access it! Please note that the minimum
          amount is N2,000 and maximum is N50,000 per withdrawal. For instance,
          if you want to access 70,000 out of your earned salary, all you have
          to do is withdraw twice just like an ATM.
        </p>
      </div>
    ),

    category: "Earnipay Flexible Salary Access",
  },
  {
    question: "Who bears the cost of accessing earned salaries?",
    answer: (
      <div className="faq-answer">
        <ul className="list-disc">
          <span>There are three options to cover costs:</span>
          <li>Defaulty, the cost is borne by the employee.</li>
          <li>
            The employer can choose to pay the withdrawal cost on behalf of the
            employee.
          </li>
          <li>Both the employer and employee can split the cost into 50/50.</li>
        </ul>
      </div>
    ),

    category: "Earnipay Flexible Salary Access",
  },
  {
    question:
      " Why does my employer have to add me on Earnipay before I can use Earnipay?",
    answer: (
      <div className="faq-answer">
        <p>
          The technology we developed to offer Earnipay relies on two key data
          points. First, you are an employee and second, you have a monthly
          fixed/base salary. Your employer adding you on Earnipay guarantees you
          are employed. Your employer also provides your monthly salary
          information which our system divides by the number of days in the
          month to know what you earn per day, enabling you to access your daily
          earnings. We do this all for you!
        </p>
      </div>
    ),

    category: "Earnipay Flexible Salary Access",
  },
  {
    question: " How do I sign up and use Earnipay?",
    answer: (
      <div className="faq-answer">
        <p>
          <span className="font-bold">Employers:</span> You can sign up and use
          Earnipay in 4 easy steps:
        </p>
        <ul className="list-disc">
          <li>
            <span className="font-bold">Register:</span> You can sign up&nbsp;
            <a href="https://earnipay.com/" className="text-blue-500">
              here
            </a>
            . We will need your
            <span className="font-bold">
              company name and address, registration number, total number of
              employees, Tax Identification Number (TIN), total net monthly
              payroll.
            </span>
          </li>
          <li>
            <span className="font-bold">Verified:</span> The information you
            provide will be verified and you will have instant access to a
            dashboard.
          </li>
          <li>
            <span className="font-bold">Add employees:</span> Via your
            dashboard, you can upload your employees’ information by clicking&nbsp;
            <span className="font-bold">Add New Employees </span> - an automated
            email and SMS is sent inviting them to activate their account.
          </li>
          <li>
            <span className="font-bold">Manage:</span> Via your dashboard, you
            can track/view withdrawals, manage your company’s activities among
            other things.
          </li>
        </ul>

        <ol className="list-disc">
          <p>For Employees:</p>
          <li>
            <span className="font-bold">Accept Invitation:</span> Once your
            employer adds you on Earnipay, you will receive an invite via email
            and SMS asking you to activate your account.
          </li>
          <li>
            <span className="font-bold">Activate:</span> Employees activate
            their accounts by using the&nbsp;
            <a href="https://earnipay.com/" className="text-blue-500">
              web app
            </a>
            , mobile app on
            <a
              href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
              className="text-blue-500 pl-2"
            >
              Playstore
            </a>&nbsp;
            or&nbsp;
            <a
              href="https://apps.apple.com/ng/app/earnipay/id1582293975"
              className="text-blue-500 pr-2"
            >
              App Store
            </a>
            or *347*729# and setting their withdrawal pins.
          </li>
          <li>
            <span className="font-bold">Withdraw:</span> You can withdraw up to 50% of your earned salaries.You can view what you can access per time via your Earnipay account. You can withdraw a minimum of N2,000 and a maximum of N50,000 per withdrawal. This means if you want to withdraw more than 50,000, you will withdraw more than once just like an ATM.
          </li>
          <p>
            If you experience troubles signing up or using your port, you can
            use the Live Chat or send us an email at
            <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
              hello@earnipay.com
            </a>
          </p>
        </ol>
      </div>
    ),
    category: "Getting started with Earnipay",
  },
  {
    question: " How long does it take to sign up?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          <span>For Employers:</span> Registering takes 3 minutes. Verifying your information is done within
          24 hours.
        </p>
        <p className="py-2">
          <span>For Employees:</span> Activating your account takes about 2 minutes. Withdrawing your
          earnings takes just 30 seconds.
        </p>
      </div>
    ),

    category: "Getting started with Earnipay",
  },
  {
    question: "How do I download the Earnipay app?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          <span>For Employers:</span>
          You can download mobile app on&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore
          </a>&nbsp;
          or&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            App Store
          </a>&nbsp;
        </p>
      </div>
    ),

    category: "Getting started with Earnipay",
  },
  {
    question: "I can’t find Earnipay on the iOS app store?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Earnipay is currently only available on the&nbsp;
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore
          </a>
          . To access the app on iOS, you would need to download Testflight then
          click on the link&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            here
          </a>
          . We are working on rolling out our app on the iOS app store.
        </p>
        <p>
          Also, you can also access Earnipay using the&nbsp;
          <a href="https://earnipay.com/" className="text-blue-500">
            web app
          </a>&nbsp;
          or USSD- *347*729#.
        </p>
      </div>
    ),

    category: "Employers",
  },
  {
    question:
      "How do I register if I don't receive an invite from my employer?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Please note that you only receive an invite if your employer has added
          you to Earnipay. If your employer is signed up on Earnipay, ask them
          to add you. Once they add you, you will get an automatic invite via
          SMS and email.
        </p>
        <p className="py-2">
          <span>For Employees:</span>
          Activating your account takes about 2 minutes. Withdrawing your
          earnings takes just 30 seconds.
        </p>
        <p>
          If you experience troubles signing up or using your port, you can use
          the Live Chat or send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Getting started with Earnipay",
  },
  {
    question: "What if my employer is not yet signed up?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          To access Earnipay as an employee, your employer has to be signed up.
        </p>
        <p className="py-2">
          If you would like your employer to get signed up, you can also
          <a
            href="https://earnipay.com/refer-employer"
            className="text-blue-500 pl-2"
          >
            refer your employer
          </a>&nbsp;
          and a personalised email will be sent telling them about the benefits
          of Earnipay. Not to worry, you can choose to remain anonymous as the
          referrer.
        </p>
      </div>
    ),

    category: "Getting started with Earnipay",
  },
  {
    question: "I have a salary advance policy, how is this different?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Salary advance is a loan, Earnipay is not a loan. With Earnipay,
          employees can access their earned salaries. Enabling employees to
          access their earned salaries is not an advance. They have earned it!
          This is why Earnipay is great; employees are using what they have
          earned to fund their everyday lifestyle / emergencies and take
          advantage of opportunities which enables them to practice better
          financial habits and live within their means.
        </p>
      </div>
    ),

    category: "Salaries",
  },
  {
    question: " What is the Earnipay employer dashboard?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Each employer is given an administrative dashboard to manage all
          employees activities on Earnipay. Activities like adding employees,
          removing employees, viewing employees’ withdrawals and many more.
        </p>
        <p>
          Need help with your dashboard, you can reach us on social media or
          send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500">&nbsp;
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "What does the Groups section on the dashboard mean?",
    answer: (
      <div className="faq-answer">
        <p className="py-2">
          Defaulty, Earnipay enables employees access up to 50% of their
          employees have earned. However, some employers may want some of their
          employees to access more than 50% percent. To create a group, select&nbsp;
          <span className="font-bold pl-2">create new group</span> in the&nbsp;
          <span className="font-bold">Groups</span> section.
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I create groups?",
    answer: (
      <div className="faq-answer">
        <ul>
          <li>
            <FontAwesomeIcon icon="arrow-right" className="font-bold mr-1" /> Click on <span className="font-bold pl-2">Groups </span>under the People
            section.
          </li>
          <li className="font-bold"><FontAwesomeIcon icon="arrow-right" className="font-bold mr-1" /> Create New Group </li>
        </ul>
        <p className="">
          You can watch
          <a
            href="https://www.youtube.com/watch?v=Hp3SR7dALjo"
            className="text-blue-500 pl-2"
            target="_blank"
            rel="noreferrer"
          >
            this video
          </a>&nbsp;
          to learn more about Groups.
        </p>
        <p>
          If you experience troubles signing up or using your port, you can use
          the Live Chat or send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I add employees into groups?",
    answer: (
      <div className="faq-answer">
        <span>To add an employee to a created group, simply: </span> <br />
        <ul>
          <li>
            <FontAwesomeIcon icon="arrow-right" className="font-bold mr-1" />
            Select the group already created when adding an employee to the
            dashboard or click on the
            <span className="font-bold">three dots beside</span> the employee’s
            details.
          </li>
          <li className="font-bold"> <FontAwesomeIcon icon="arrow-right" className="font-bold mr-1" /> Add to group. </li>
        </ul>
        <p>
          You can watch&nbsp;
          <a
            href="https://www.youtube.com/watch?v=Hp3SR7dALjo"
            className="text-blue-500"
            target="_blank"
            rel="noreferrer"
          >&nbsp;this video
          </a>&nbsp;
          to learn more about Groups.
        </p>
        <p>
          If you experience troubles signing up or using your port, you can use
          the Live Chat or send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500">&nbsp;
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I upload employees to the employer dashboard?",
    answer: (
      <div className="faq-answer">
        <span>To add an employee to a created group, simply: </span> <br />
        <ul className="list-none">
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Log in to your Earnipay employer dashboard
          </li>
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Click on <span className="font-bold">All Employees</span> section in
            the <span className="font-bold">People</span> section of the left
            side of the dashboard.
          </li>
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Click on<span className="font-bold"> Add New Employee.</span>
          </li>
        </ul>
        <p>
          If you experience troubles signing up or using your port, you can use
          the Live Chat or send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How can I view employees enrolled on the dashboard?",
    answer: (
      <div className="faq-answer">
        <p>
          To view enrolled employees, select&nbsp;
          <span className="font-bold">Enrolled Employees</span> on the&nbsp;
          <span className="font-bold">People </span> section of the dashboard.
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I remove an employee from the dashboard?",
    answer: (
      <div className="faq-answer">
        <p>
          Click on the <span className="font-bold">three dots</span> beside the
          employee’s name and select&nbsp;
          <span className="font-bold">Deactivate. </span>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question:
      "How do I view the total monthly and weekly withdrawals on the dashboard?",
    answer: (
      <div className="faq-answer">
        <p>
          To view your employees’ total weekly and monthly withdrawals, click on
          <span className="font-bold"> Dashboard</span> on the dashboard.
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I view employee withdrawals on the dashboard?",
    answer: (
      <div className="faq-answer">
        <p>
          To view your employees’ usage and withdrawals, click on&nbsp;
          <span className="font-bold">Withdrawal </span>on the dashboard
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How do I add administrators on the dashboard?",
    answer: (
      <div className="faq-answer">
        <ul className="list-none">
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Click <span className="font-bold">Settings.</span>
          </li>
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Click <span className="font-bold">Administrators.</span>
          </li>
          <li>
            &nbsp;
            <FontAwesomeIcon
              icon="arrow-right"
              className="font-bold ml-2"
            />&nbsp;
            Click on<span className="font-bold"> New Administrators.</span>
          </li>
        </ul>
        <p>
          If you experience troubles adding administrators, you can use the Live
          Chat or send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: " I need to resend the invite to my employees.",
    answer: (
      <div className="faq-answer">
        <p>
          click the <span className="font-bold">three dots </span>beside the
          employee’s name and select&nbsp;
          <span className="font-bold">Resend Invite.</span>
        </p>
      </div>
    ),

    category: "Earnipay Dashboard",
  },
  {
    question: "How much of salaries can be withdrawn per time?",
    answer: (
      <div className="faq-answer">
        <p>
          Employees can withdraw up to 50% of their earned salaries. What is accessible per time will be shown on the employee portal.
          You are allowed a minimum of N2,000 and a maximum of N50,000 per withdrawal.
          This means a withdrawal of 100,000 can be done in two transactions just like an ATM.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How do I access and withdraw my earnings?",
    answer: (
      <div className="faq-answer">
        <p>
          You can access and withdraw your earnings via the mobile app on
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore
          </a>&nbsp;
          or&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            App Store
          </a>
          ,&nbsp;
          <a href="https://earnipay.com/" className="text-blue-500">
            web app
          </a>&nbsp;
          or USSD - *347*729#
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How are my earnings for each day calculated?",
    answer: (
      <div className="faq-answer">
        <p>
          Your monthly net salary is spread across the number of days in the
          month. If you earn a monthly net salary of N150,000 in a 30 days
          month, this means that you would earn N5,000 per day. You can see your
          breakdown of earned salaries on the mobile app on
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore
          </a>&nbsp;
          or&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            App Store
          </a>
          ,&nbsp;
          <a href="https://earnipay.com/" className="text-blue-500">
            web app
          </a>&nbsp;
          or USSD - *347*729#
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How long does withdrawal take?",
    answer: (
      <div className="faq-answer">
        <p>Withdrawal is done instantly.</p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question:
      "Can I pay back money I have withdrawn from my available earnings?",
    answer: (
      <div className="faq-answer">
        <p>
          No, you do not have to pay back because this is your earned salary!
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },

  {
    question: "Does Earnipay work like a regular bank account?",
    answer: (
      <div className="faq-answer">
        <p>
          &nbsp;
          <span>No, it does not. </span>A regular bank account allows you to
          deposit and withdraw funds. Earnipay’ Flexible Salary Access allows
          you access your earned salaries for the days worked which is paid
          directly to your bank account.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question:
      " Are there days in the month I can’t access my earned salaries? ",
    answer: (
      <div className="faq-answer">
        <p>
          Yes, there are. Three days before your company’s official payday. This
          is for reconciliation purposes.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How are the withdrawn earnings repaid?",
    answer: (
      <div className="faq-answer">
        <p>
          The total amount of earned salaries withdrawn by employees via
          Earnipay is refunded to us by the employer (after deductions from
          employees’ total salary). Employers can choose to repay the withdrawn
          earnings via a transfer to our account or allow a direct debit on the
          company’s account.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question:
      "If I withdraw my earned salaries during the month via Earnipay, how much will I receive on my company’s official payday?",
    answer: (
      <div className="faq-answer">
        <p>
          Employees’ withdrawn salaries are deducted from their total net salary
          and what is left is received by the employee.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "Does Earnipay give loans?",
    answer: (
      <div className="faq-answer">
        <p>
          No, we do not give loans. With Earnipay, employees can access their
          earned salaries. Enabling employees to access their earned salaries is
          not a loan. They have earned it! This is why Earnipay is great;
          employees are using what they have earned to fund their everyday
          lifestyle / emergencies and take advantage of opportunities which
          enables them to practice better financial habits and live within their
          means.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How much of my salary can be withdrawn?",
    answer: (
      <div className="faq-answer">
        <p>
          With Earnipay, you can access up to 50% of your earned salaries using
          any of our channels -
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore
          </a>&nbsp;
          or&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            App Store
          </a>
          ,&nbsp;
          <a href="https://earnipay.com/" className="text-blue-500">
            web app
          </a>&nbsp;
          or USSD - *347*729#
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "How many times can I withdraw my earnings in a month?",
    answer: (
      <div className="faq-answer">
        <p>
          You can withdraw your earnings multiple times in the month. As long as
          you have earned it, you can access it. Please note that the minimum
          amount is N2,000 and maximum is N50,000 per withdrawal. For instance,
          if you want to access 70,000 out of your earned salary, all you have
          to do is withdraw twice just like an ATM.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: "Can I change my account details on my profile?",
    answer: (
      <div className="faq-answer">
        <p>
          No, you cannot. To protect the security of your account information
          provided, only your company has admin rights to change this
          information. You can reach out to your employer to make any changes.
        </p>
      </div>
    ),

    category: "Earnings Payment",
  },
  {
    question: " What happens to my salary if I don’t withdraw it?",
    answer: (
      <div className="faq-answer">
        <p>
          You will receive your full salary on your official payday if you do
          not withdraw your earned salaries.
        </p>
      </div>
    ),

    category: "Salaries",
  },
  {
    question:
      "Why are my daily earnings for this month different from last month’s daily earnings?",
    answer: (
      <div className="faq-answer">
        <p>
          Your daily earnings are calculated based on the number of days. For
          example, you earn NGN 150,000. In a 28-days month like February, your
          daily earnings will be NGN 5,357.142. In a 31-days month like March,
          your daily earnings will be NGN 4,838. 079.
        </p>
      </div>
    ),

    category: "Salaries",
  },
  {
    question: "How does Earnipay impact my expected salary on payday?",
    answer: (
      <div className="faq-answer">
        <p>
          Employees’ withdrawn salaries are deducted from their total net salary
          and what is left is received by the employee. This means if you earn
          N150,000 monthly and have withdrawn N25,000 using Earnipay, you will
          receive N125,000 from your company on your official payday.
        </p>
      </div>
    ),

    category: "Salaries",
  },
  {
    question: "How can I access Earnipay?",
    answer: (
      <div className="faq-answer">
        <p>
          You can access Earnipay using our
          <a href="https://earnipay.com/" className="text-blue-500"> web app&nbsp;
          </a>
          mobile app on
          <a
            href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
            className="text-blue-500 pl-2"
          >
            Playstore&nbsp;
          </a>
          or&nbsp;
          <a
            href="https://apps.apple.com/ng/app/earnipay/id1582293975"
            className="text-blue-500 pr-2"
          >
            App Store
          </a>
          , or USSD - *347*729#
        </p>
      </div>
    ),

    category: "Channels",
  },
  {
    question: "I see a countdown timer on the web app?",
    answer: (
      <div className="faq-answer">
        <p>
          It is a daily countdown to 5pm which signifies close of business. At
          close of business, you have earned your salaries for that day. Yayyy!
        </p>
      </div>
    ),

    category: "Channels",
  },
  {
    question: "I did not get the OTP pin to activate my account?",
    answer: (
      <div className="faq-answer">
        <p>
          This may be due to network connection. Please try at a later time. You
          can send us an email at
          <a href="mailto: hello@earnipay.com" className="text-blue-500 pl-2">
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Channels",
  },
  {
    question: "How do I contact customer support?",
    answer: (
      <div className="faq-answer">
        <p>
          Please send us an email at<a href="mailto: hello@earnipay.com" className="text-blue-500">&nbsp;
            hello@earnipay.com
          </a>&nbsp;
          or send us a message using our live chat box.
        </p>
      </div>
    ),

    category: "Support",
  },
  {
    question:
      "I have more questions that have not been covered. Who do I speak to?",
    answer: (
      <div className="faq-answer">
        <p>
          We are happy to answer all of your questions! You can send us a direct
          message on <a href="https://www.instagram.com/getearnipay/" className="text-blue-500">Instagram</a>,
          &nbsp; <a href="https://web.facebook.com/Earnipay/" className="text-blue-500">Facebook</a>,
          &nbsp;<a href="https://twitter.com/earnipay" className="text-blue-500">Twitter</a>,&nbsp;
          <a href="https://www.linkedin.com/company/earnipay/" className="text-blue-500">LinkedIn</a>&nbsp;
          and&nbsp; our webchat or
          an email at<a href="mailto: hello@earnipay.com" className="text-blue-500">&nbsp;
            hello@earnipay.com
          </a>
        </p>
      </div>
    ),

    category: "Support",
  },
  {
    question:
      "Where are you located?",
    answer: (
      <div className="faq-answer">
        <p>
          Our office is located at Plot 8, Providence Street, Lekki Phase 1, Lagos but we mostly work remotely.
        </p>
      </div>
    ),

    category: "Support",
  },
  {
    question:
      "What are your social media channels?",
    answer: (
      <div className="faq-answer">
        <p>
          You can find us on&nbsp;
          <a href="https://www.instagram.com/getearnipay/" className="text-blue-500">Instagram</a>,&nbsp;
          <a href="https://twitter.com/earnipay" className="text-blue-500">Twitter</a>&nbsp;
          <a href="https://www.linkedin.com/company/earnipay/" className="text-blue-500">LinkedIn</a> and&nbsp;
          <a href="https://web.facebook.com/Earnipay/" className="text-blue-500">Facebook</a>&nbsp;
        </p>
      </div>
    ),
    category: "Support",
  },
];

