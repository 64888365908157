import React from 'react';
import webinar2 from "assets/images/webinar-marketing/webinar2.jpg";
import HubspotContactForm from './WebinarTwoForm';

const  WebinarTwo = () =>  {
    return (
      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">Navigating through a toxic work culture</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded'>
                    <img src={webinar2} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>
                    If going to work makes you feel stressed or anxious and you’re experiencing a loss of focus, declining confidence or even physical manifestations of anxiety-like headaches, then you’re likely working in a toxic environment. In fact, more than 70% of employees report that they have worked in at least one toxic environment in the course of their careers.
                      <br /> <br />
                      Surviving a toxic workplace is tough for everyone, whether you're an HR manager tasked with shaping culture, a team lead or an employee trying to do your best work, but it’s absolutely possible.
                      <br /><br />
                      Join <span className='font-semibold'>Yemi Fasheun</span> (Chief Talent Officer, YF Talent Ventures) in this Earnipay Webinar series for expert tips on how to navigate a toxic work environment.
                    </div>

                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6" style={{"minHeight":"500px", "border":"1px solid #9CA3AF"}}>
                  <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                  <HubspotContactForm/>
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default WebinarTwo;

