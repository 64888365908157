import React from 'react';
import Header from "components/common/header.jsx";
import NewFooter from 'components/common/new-footer';
const NullLayout = (props) => {
    return ( 
        <React.Fragment>
            <Header />
            <div className="mt-16 md:mt-20 page-body overflow-auto">
                {props.children}
            </div>
            <NewFooter />
        </React.Fragment>
     );
}
 
export default NullLayout;