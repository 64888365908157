import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo-new.svg";
import facebook from "assets/images/social/facebook-black.svg";
import twitter from "assets/images/social/x-black.svg";
import instagram from "assets/images/social/instagram-black.svg";
import linkedin from "assets/images/social/linkedin-black.svg";
// import certification from "assets/images/earnipay-certification.png";
import ndpraudit from "assets/images/ndpr-audit.svg";

const NewFooter = () => {
    return (
        <section className="font-aeonik" style={{background: 'linear-gradient(to bottom, transparent, #E5FCFF'}}>
            <div className="container pt-20 pb-28 relative">

                <div className="lg:flex lg:space-x-14 justify-between items-start">
                    <div className="earnipay-and-cert-footer lg:w-1/4 lg:flex-shrink-0">

                        <div>
                            <img src={logo} alt="Earnipay logo" className="footer-logo" />
                            <div className="text-sm text-gray-400 mt-1">
                                Do Money Better
                            </div>
                        </div>
                        <div className="w-auto flex flex-col md:w-48 xl:w-auto">
                            
                            <div className="footer-socials-wrapper lg:block">
                                <div className="flex my-6 space-x-4">                                    
                                    <Link to={{ pathname: "https://www.twitter.com/earnipay" }} target="_blank" className="inline-block footer-social-icon">
                                        <img src={twitter} alt="twitter" className="object-contain" />
                                    </Link>
                                    <Link to={{ pathname: "https://www.linkedin.com/company/earnipay" }} target="_blank" className="inline-block footer-social-icon">
                                        <img src={linkedin} alt="linkenin" className="object-contain" />
                                    </Link>
                                    <Link to={{ pathname: "https://www.instagram.com/getearnipay/" }} target="_blank" className="inline-block footer-social-icon">
                                        <img src={instagram} alt="instagram" className="object-contain" />
                                    </Link>
                                    <Link to={{ pathname: "https://www.facebook.com/Earnipay" }} target="_blank" className="inline-block footer-social-icon">
                                        <img src={facebook} alt="facebook" className="object-contain" />
                                    </Link>
                                </div>
                            </div>

                            <img src={ndpraudit} alt="Earnipay NDPR Certification" className="ndpr-icon pt-2"/>

                            <div className="hidden text-base">
                                &copy; <span>{new Date().getFullYear()}</span> Earnipay Inc. All rights reserved.
                            </div>
                            <div className="hidden text-base">
                                Plot 8, Providence Street, Lekki 1, Lagos.
                            </div>
                        </div>
                    </div>

                    {/* <div className="lg:w-3/4 mt-10 lg:mt-0 grid grid-cols-2 md:grid-cols-4 gap-x-2 xl:gap-x-16 gap-y-10 md:gap-y-0 text-sm"> */}
                    <div className="lg:w-3/4 mt-10 lg:mt-0 grid grid-cols-2 md:grid-cols-4 gap-x-2 xl:gap-x-16 gap-y-10 md:gap-y-0 text-sm" style={{ marginLeft: 0 }}>
                        <div className="space-y-4">
                            <div className="text-gray-500 pb-2 text-sm">
                                Business
                            </div>
                            
                            <div>
                                <Link to="#" className="hover:underline footer-link">
                                    Credit
                                </Link>
                            </div>
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Accounts
                                </Link>
                            </div>
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Payments
                                </Link>
                            </div>
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Payroll
                                </Link>
                            </div>
                            <div>
                                <Link to="/" target="_blank" className="hover:underline footer-link">
                                    On-Demand Pay
                                </Link>
                            </div>
                            {/* <div>
                                    <Link to="/careers" target="_blank" className="hover:underline footer-link">Careers</Link>
                                </div> */}
                            {/* <div>
                                <Link to={{ pathname: "https://blog.earnipay.com/" }} target="_blank" rel="noreferrer" className="hover:underline footer-link">
                                    Blog
                                </Link>
                            </div> */}
                        </div>

                        <div className="space-y-4">
                            <div className="text-gray-500 pb-2 text-sm">
                                Personal
                            </div>                            
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Loans
                                </Link>
                            </div>                          
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Savings
                                </Link>
                            </div>                         
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    On-Demand Pay
                                </Link>
                            </div>                              
                            <div>
                                <Link to="/" className="hover:underline footer-link">
                                    Rewards
                                </Link>
                            </div>                            
                        </div>

                        <div className="space-y-4">
                            <div className="text-gray-500 pb-2 text-sm">
                                Company
                            </div>
                            
                            <div>
                                <Link to="#" className="hover:underline footer-link">
                                    About Us
                                </Link>
                            </div>
                            <div>
                                <Link to={{pathname:"https://earnipay.bamboohr.com/jobs"}} target="_blank" className="hover:underline footer-link">
                                    Careers
                                </Link>
                            </div>
                            <div>
                                <Link to="/privacy-policy" className="hover:underline footer-link">
                                    Privacy Policy
                                </Link>
                            </div>
                            <div>
                                <Link to="/terms" className="hover:underline footer-link">
                                    Terms and Conditions
                                </Link>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="text-gray-500 pb-2">
                                Contact Us
                            </div>
                            <div>
                                <div className="footer-link">
                                    For Business Enquiries:
                                </div>
                                <Link to={{ pathname: "mailto: business@earnipay.com" }} className="hover:underline text-ep-primary-500" onClick={(e) => { window.location = e.target.getAttribute("href"); e.preventDefault(); }}>
                                    business@earnipay.com
                                </Link>
                                <div className="pt-3 footer-link">
                                    For Personal Enquiries:
                                </div>
                                <Link to={{ pathname: "mailto: business@earnipay.com" }} className="hover:underline text-ep-primary-500" onClick={(e) => { window.location = e.target.getAttribute("href"); e.preventDefault(); }}>
                                    support@earnipay.com
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="hidden pt-10 mt-20 border-t flex justify-between">
                    <div>
                        All Rights Reserved
                    </div>
                    <div>
                        &copy; <span>{new Date().getFullYear()}</span> Earnipay Inc.
                    </div>
                </div>

            </div>
        </section>
    )
}

export default NewFooter;
