import React, { useRef, useState } from "react";
// import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow-left-4.svg";
// import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-right-4.svg";
// import Quote from "assets/images/quote.svg";
import { ReactComponent as QuoteIcon } from "assets/images/quote.svg";
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
import quoteImage from "assets/images/icons/quoteImage.svg";
// import {
//     backgroundColor,
//     borderRadius,
//     fontSize,
// } from "tailwindcss/defaultTheme";

// const slideshowProperties = {
//     arrows: false,
//     dots: false,
//     centerMode: true,
//     infinite: true,
//     autoplay: true,
//     speed: 500,
//     centerPadding: "0px",
//     slidesToScroll: 1,
//     initialSlide: 0,
//     slidesToShow: 4,
//     responsive: [
//         {
//           breakpoint: 900,
//           settings: {
//             slidesToShow: 2,
//           },
//         },
//         {
//           breakpoint: 1150,
//           settings: {
//             slidesToShow: 3,
//           },
//         },
//         {
//           breakpoint: 1300,
//           settings: {
//             slidesToShow: 4,
//           },
//         },
//     ]
// };

/*
const testimonies = [
    {
        content: "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you Earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
    {
        content: "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you Earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
    {
        content: "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you Earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
    {
        content: "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you Earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
    {
        content: "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you Earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
];
*/

/* const testimonies = [
    {
        content:
            "Earnipay was referred to us. We went for this because most employees had been going for loans. It’s seamless, and the charges are affordable. The messages we get at 5 pm are also encouraging.",
        name: "Isaac Onwere",
        company: "Compliance Professionals Plc",
    },
    {
        content:
            "Our employees get quick access to their money because there would usually be many levels to approve a salary advance, and Earnipay has cut all those off. For my employees, it is easy. I simply get an email that lets me know the breakdown for everything.",
        name: "Kehinde Ishie",
        company: "Tuteria Limited",
    },
    {
        content:
            "An excellent innovation. It is easy to access while the web and apps are user friendly. Earnipay has done an outstanding job in allowing staff access to their pay.",
        name: "Obiora Mgbodile",
        company: "Brooks and Blake Nigeria Limited",
    },
    {
        content:
            "It was an avenue to give wage access to employees. They can access their salaries whenever they need them. We were looking for an opportunity for our employees to be well taken care of and Earnipay was that.",
        name: "Fiyinfoluwa Adeoti",
        company: "Genesys Health Information System Limited",
    },
    {
        content:
            "My employees can request their urgent 2k when they need it. I find that loans are stressful to request for when it’s just 5,000 naira or more.",
        name: "Favour Eze",
        company: "Thrive Agric",
    },
    {
        content:
            "It is a very good product. So much has been said about Talent Retention Strategies in HR, this is one of them. The beauty of it is that employees can access their money before the end of the month. It is a good initiative. If the employees are fine, they will be productive. The fact it is what you have earned so far makes it more safe and credible - there are no risks to the employer.",
        name: "Oluwole Olajide",
        company: "George Houston Resources Limited",
    },
    {
        content:
            "It has been a good experience. The benefits are very glaring to see. Our employees have been accessing the product month on month.",
        name: "Peter Adeyemi",
        company: "Curacel Systems Limited",
    },
    {
        content:
            "Awesome service! We had an orientation to learn how it works. I mostly use Earnipay for emergencies, and it has been so seamless. I no longer need to take loans or pay interest. Earnipay helps me manage my withdrawals and expenses.",
        name: "Omolade Bisiriyu",
        company: "Bluechip",
    },
    {
        content:
            "I love using Earnipay. It helps me access my salary at all times, and I no longer have to wait till payday to get funds.",
        name: "Emmanuel Oyibo",
        company: "Thrive Agric",
    },
    {
        content:
            "I consider myself an evangelist of Earnipay! I got my colleague to activate and start withdrawing this year because he was always in bad debt. Earnipay has so far helped me and my friend avoid recurring debt.",
        name: "Shamsu Musa Abdulkarim",
        company: "Thrive Agric",
    },
    {
        content:
            "Amazing product. I like Earnipay, especially with the low fees and fast withdrawals.",
        name: "Samson Yahaya",
        company: "Compliance Professionals Plc",
    },
    {
        content:
            "I tried the app once and I loved it. I downloaded the app and found it easy to track my earnings. I did not need a manual to use the app.",
        name: "Kasali Aisha Yewande",
        company: "Bluechip",
    },
    {
        content:
            "Earnipay is a very good idea and helps Thrive Agric’s staff a lot. Most times, we do not have money before half the month and we need money to eat and buy basic things at our various homes so most of us turn to Earnipay to get funds. This is basically happening because of the increase in price of things in the country and salaries are not enough to keep us running before the next one is paid.",
        name: "Solomon Adeyemi",
        company: "Thrive Agric",
    },
    {
        content:
            "The platform is very reliable and fast. Earnipay comes as one of the easiest financially stable platform to use. Funds withdrawals are quick. Thank you earnipay for making life easy when sapa hold person before month end.",
        name: "Sunday Gana",
        company: "Thrive Agric",
    },
    {
        content: (
            <>
                {" "}
                <Link
                    to={{
                        pathname:
                            "https://twitter.com/Diseye_/status/1525151000809570307?s=20&t=gxhZj79EmhLYZdZD62OxPA",
                    }}
                    target="_blank"
                    className="text-ep-primary-400 hover:underline"
                >
                    @earnipay
                </Link>{" "}
                actually credits faster than inter-bank transfers. Mad.
            </>
        ),
        name: (
            <>
                Amitriptyline{" "}
                <Link
                    to={{
                        pathname:
                            "https://twitter.com/Diseye_/status/1525151000809570307?s=20&t=gxhZj79EmhLYZdZD62OxPA",
                    }}
                    target="_blank"
                    className="text-ep-primary-400 hover:underline"
                >
                    (@Diseye_)
                </Link>
            </>
        ),
        company: "Twitter",
    },
    {
        content:
            "Running a business can be demanding, having motivated employees is paramount to our success and with earnipay’s flexible salary payment solutions, productivity isn’t a demand anymore, it’s a lifestyle.",
        name: "Solomon Dawudu",
        company: "Takeout Media",
    },
    {
        content:
            "Earnipay's flexible salary has been such a positive impact in our organisation, our employees are able to access their earned salaries at will without any added pressure on our payroll and accounting systems.",
        name: "Adebowale Banjo",
        company: "Venia Group",
    },
];
 */

const testimonies = [
    {
        content: "It has always been a race from pillar to post anytime there is a contract or job before we crossed paths with Earnipay. It has been a rain of opportunities ever since. The flexibility of Earnipay’s loans allowed us to keep our projects on schedule. Their understanding of our specific needs made all the difference.",
        name: "Engr. Adekunle Atta",
        company: "Managing Director @Kangau Technology Global Service",
    },
    {
        content:
            "I recently had the pleasure of experiencing the services of your organization and I must say, they exceeded my expectations in every way. From the moment I reached out, their team demonstrated a remarkable professionalism",
        name: "Taiwo Ifeimu",
        company: "Managing Director @Taitrice Nigeria Ltd",
    },
    {
        content:
            "Having started a business and financial relationship with Earnipay, we have grown in confidence to that of a company that has started taking projects of any costs. Earnipay is a firm with reliable source of confidence to us.",
        name: "Engr. Amiola Oyebiyi",
        company: "Managing Director @Omanco Construction Ltd",
    },
    {
        content:
            "With the little time we have been doing business with you, honestly we rate you 100% OK, your service are very very OK, we are here to say your service are the best among others, more blessings to the company more blessings to the staff.",
        name: "Engr. Adeleke Akeem Adeyemi",
        company: "Managing Director @Akleks Technical Ventures",
    },
    {
        content:
            "An excellent innovation. It is easy to access while the web and apps are user friendly. Earnipay has done an outstanding job in allowing staff access their pay.",
        name: "Obiora Mgbodile",
        company: "Brooks and Blake Nigeria Limited",
    },
];

const Testimony = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const slickRef = useRef(null);

    const showBox = (testimony) => {
        if (testimony.content) {
            return "border shadow-lg";
        } else {
            return "";
        }
    };

    const slideshowProperties = {
        dots: true,
        appendDots: (dots) => (
            <ul style={{ margin: "0px" }}>
                {dots.map((dot, index) => (
                    <li
                        key={index}
                        onClick={() => {
                            slickRef?.current?.slickGoTo(index);
                            setActiveIndex(index);
                        }}
                        className={`testimonial-slider-dot ${
                            index === activeIndex
                                ? "testimonial-slider-active-dot"
                                : ""
                        }`}
                    />
                ))}
            </ul>
        ),
        beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
        // other properties...
    };

    return (
        <div>
            <div className="mt-16 overflow-hidden testimony-wrap">
                <div className="container hidden">
                    <div
                        className="page-title text-ep-primary"
                        style={{ textAlign: "left", maxWidth: "none" }}
                    >
                        Don't just take our <br className="hidden xl:block" />{" "}
                        word for it
                    </div>
                </div>

                {/* slick-slide slick-active slick-center slick-current */}
                <div className="py-10 relative testimonials-landing">
                    <QuoteIcon className="testimonials-landing-quote-bg" />
                    {/* <img src={Quote} className="testimonials-landing-quote-bg" /> */}
                    <Slider
                        {...slideshowProperties}
                        ref={slickRef}
                        className="max-w-[600px] h-full mx-auto"
                        dots={true}
                    >
                        {testimonies.map((testimony, index) => (
                            <div
                                key={index}
                                className="px-2 h-full"
                            >
                                <div
                                    className={`w-full h-full mx-auto text-base flex flex-col flex-shrink-0 p-10 rounded-3xl transition-transform duration-300 ${
                                        index === activeIndex
                                            ? "transform scale-105 ease-in-out active-testimonial"
                                            : "inactive-testimonial" /* opacity-90 blur-sm */
                                    } ${showBox(testimony)}`}
                                >
                                    <div className="text-gray-600">
                                        {testimony.content}
                                    </div>
                                    <div className="flex justify-end">
                                        <img
                                            src={quoteImage}
                                            className="h-10"
                                            alt=""
                                        />
                                    </div>

                                    <div className="mt-10 overflow-hidden">
                                        <div
                                            title={testimony.name}
                                            className="text-2xl"
                                        >
                                            {testimony.name}
                                        </div>
                                        <div
                                            title={testimony.company}
                                            className="text-xl text-gray-600"
                                        >
                                            {testimony.company}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                {/* <div className="container">
                    <div className="flex space-x-4">
                        <div onClick={() => slickRef.current.slickPrev()} className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer group hover:bg-ep-primary-500">
                            <ArrowLeft className="text-ep-primary-500 group-hover:text-white customizeSVG" />
                        </div>
                        <div onClick={() => slickRef.current.slickNext()} className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center cursor-pointer group hover:bg-ep-primary-500">
                            <ArrowRight className="text-ep-primary-500 group-hover:text-white customizeSVG" />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Testimony;
