import React, { useEffect, useRef, } from "react";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";

import GeneralLayout from "./components/layouts/generalLayout";
import BlankLayout from "./components/layouts/blankLayout";
import NullLayout from "./components/layouts/nullLayout";
import Error404 from "./components/misc/error404";
import XFrameOptions from "./components/misc/x-frame-options";

import Home from "./pages/home/home";
import HomeV1 from "./pages/home/oldVersions/home-v1";
import HomeV2 from "./pages/home/oldVersions/home-v2";
import HomeV3 from "./pages/home/oldVersions/home-v3";
import bookDemo from "./pages/book-demo";
import bookDemoV2 from "./pages/book-demo-v2";
// import productsInvoice from "./pages/products/invoice";
// import productsOnDemandPay from "./pages/products/on-demand-pay";
// import productsBulkPayment from "./pages/products/bulk-payment";
// import productsPayroll from "./pages/products/payroll";
// import ProductsSavings from "pages/products/Savings";
// import ProductsRewards from "pages/products/Rewards";
// import BusinessPayroll from "pages/business/payroll";
// import Company from "pages/company";
// import Employers from "./pages/Employers";
// import Employees from "./pages/Employees";
import AppointmentSuccess from "pages/marketingPage/AppointmentSuccess";
import Terms from "./pages/others/terms";
import PrivacyPolicy from "./pages/others/privacyPolicy";
import MarketingForm from "./pages/marketingPage/marketingForm";
import MarketingRedirectPage from "./pages/marketingPage/MarketingRedirectPage";
import WebinarRedirectPage from "pages/marketingPage/WebinarRedirectPage";
import WebinarOne from "./pages/marketingPage/WebinarOne";
import WebinarTwo from "pages/marketingPage/WebinarTwo";
import WebinarThree from "pages/marketingPage/WebinarThree";
import WebinarFour from "pages/marketingPage/WebinarFour";
import GuideTwo from "pages/marketingPage/GuideTwo";
import GuideThree from "pages/marketingPage/GuideThree";
import GuideFinHealth from "pages/marketingPage/GuideFinancialHealth";
import GuideUltimateJobOfferChecklist from "pages/marketingPage/GuideUltimateJobOfferChecklist";
import GuideDaringCeoSeries from "pages/marketingPage/GuideDaringCeoSeries";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import WebinarFive from "pages/marketingPage/WebinarFive";
import WebinarSix from "pages/marketingPage/WebinarSix";
import WebinarSeven from "pages/marketingPage/WebinarSeven";
import CareersPage from "pages/careers/CareersPage";
import CookiePolicy from "pages/others/CookiePolicy";
import CookieConsentModal from "utils/CookieConsentModal";
// import BusinessPayment from "pages/business/payment";
// import BusinessODP from "pages/business/odp";
// import BusinessCredit from "pages/business/credit";
// import BusinessAccount from "pages/business/account";

library.add(fas);

const ScrollToTopComponent = () => {
	const mounted = useRef(false);
	useEffect(() => {
		if (!mounted.current) {
			//componentDidMount
			mounted.current = true;
		} else {
			//componentDidUpdate
			window.scrollTo(0, 0);
		}
	});
	return null;
};
export const ScrollToTop = withRouter(ScrollToTopComponent);

const RouteLayout = ({
	component: Component,
	layout: Layout,
	authUser,
	authRole,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) => {
			const user = "";
			const user_role = "";

			if (authUser) {
				if (authUser === "loggedIn") {
					if (user.loggedIn === true) {
						if (authRole) {
							if (authRole.includes(user_role)) {
								return (
									<Layout>
										<Component {...props} />
									</Layout>
								);
							} else {
								// alert("Access denied due to user role");
								return <Redirect to="/401" />;
							}
						} else {
							// no roles required, you only need to be logged in
							return (
								<Layout>
									<Component {...props} />
								</Layout>
							);
						}
					} else {
						// alert("need to be logged in);
						return <Redirect to="/login" />;
					}
				} else if (authUser === "loggedOut") {
					if (user.loggedIn === false) {
						return (
							<Layout>
								<Component {...props} />
							</Layout>
						);
					} else {
						// alert("already logged in; need to be logged out to access);
						return <Redirect to="/dashboard" />;
					}
				}
			} else {
				// public page, no auth-yes or auth-no required
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

function App() {
	return (
		<React.Fragment>
			<BrowserRouter>
				<ScrollToTop />
				<CookieConsentModal />
				<Switch>
					<RouteLayout exact path="/book-demo" layout={BlankLayout} component={bookDemo} />
					<RouteLayout exact path="/book-demo/v2" layout={BlankLayout} component={bookDemoV2} />

					{/* <RouteLayout exact path="/products/invoice" layout={GeneralLayout} component={productsInvoice} />
					<RouteLayout exact path="/products/on-demand-pay" layout={GeneralLayout} component={productsOnDemandPay} />
					<RouteLayout exact path="/products/bulk-payment" layout={GeneralLayout} component={productsBulkPayment} />
					<RouteLayout exact path="/products/payroll" layout={GeneralLayout} component={productsPayroll} />
					<RouteLayout exact path="/products/payroll" layout={GeneralLayout} component={productsPayroll} /> */}
					<RouteLayout exact path="/careers" layout={GeneralLayout} component={CareersPage} />
					{/* <RouteLayout exact path="/products/savings" layout={GeneralLayout} component={ProductsSavings} />
					<RouteLayout exact path="/products/rewards" layout={GeneralLayout} component={ProductsRewards} />


					<RouteLayout exact path="/business" layout={GeneralLayout} component={Employers} />
					<RouteLayout exact path="/company" layout={GeneralLayout} component={Company} />
					<RouteLayout exact path="/business/account" layout={GeneralLayout} component={BusinessAccount} />
					<RouteLayout exact path="/business/credit" layout={GeneralLayout} component={BusinessCredit} />
					<RouteLayout exact path="/business/payment" layout={GeneralLayout} component={BusinessPayment} />
					<RouteLayout exact path="/business/payroll" layout={GeneralLayout} component={BusinessPayroll} />
					<RouteLayout exact path="/business/odp" layout={GeneralLayout} component={BusinessODP} />
					<RouteLayout exact path="/personal" layout={GeneralLayout} component={Employees} /> */}
					
					<RouteLayout exact path="/terms" layout={GeneralLayout} component={Terms} />
					<RouteLayout exact path="/privacy-policy" layout={GeneralLayout} component={PrivacyPolicy} />
					<RouteLayout exact path="/cookie-policy" layout={GeneralLayout} component={CookiePolicy} />

					<RouteLayout exact path="/campaign/guide/5-ways-employee-financial-stress-is-costing-your-business" layout={GeneralLayout} component={MarketingForm} />
					<RouteLayout exact path="/campaign/guide/how-to-stop-living-from-payday-to-payday" layout={GeneralLayout} component={GuideTwo} />
					<RouteLayout exact path="/campaign/guide/the-future-of-employee-benefits" layout={GeneralLayout} component={GuideThree} />
					<RouteLayout exact path="/campaign/guide/improving-the-financial-health-of-your-employees" layout={GeneralLayout} component={GuideFinHealth} />
					<RouteLayout exact path="/campaign/guide/the-ultimate-job-offer-checklist" layout={GeneralLayout} component={GuideUltimateJobOfferChecklist} />
					<RouteLayout exact path="/campaign/guide/the-daring-ceo-series" layout={GeneralLayout} component={GuideDaringCeoSeries} />
					<RouteLayout exact path="/campaign/guide/thank-you" layout={GeneralLayout} component={MarketingRedirectPage} />
					<RouteLayout exact path="/campaign/webinar/survival-skills-for-women" layout={GeneralLayout} component={WebinarOne} />
					<RouteLayout exact path="/campaign/webinar/navigating-through-a-toxic-workplace-culture" layout={GeneralLayout} component={WebinarTwo} />
					<RouteLayout exact path="/campaign/webinar/how-to-keep-your-employees-motivated-even-in-tough-times" layout={GeneralLayout} component={WebinarThree} />
					<RouteLayout exact path="/campaign/webinar/how-to-build-a-compelling-talent-attraction-strategy" layout={GeneralLayout} component={WebinarFour} />
					<RouteLayout exact path="/campaign/webinar/how-do-i-keep-my-best-talent" layout={GeneralLayout} component={WebinarFive} />
					<RouteLayout exact path="/campaign/webinar/helping-employees-thrive-through-disruptions" layout={GeneralLayout} component={WebinarSix} />
					<RouteLayout exact path="/campaign/webinar/improving-employee-experience-through-financial-wellness" layout={GeneralLayout} component={WebinarSeven} />
					<RouteLayout exact path="/campaign/webinar/thank-you" layout={GeneralLayout} component={WebinarRedirectPage} />
					<RouteLayout exact path="/appointment/success" layout={GeneralLayout} component={AppointmentSuccess} />

					<RouteLayout exact path="/404" layout={NullLayout} component={Error404} />
					<RouteLayout exact path="/test/clickjacking" layout={NullLayout} component={XFrameOptions} />

					{/* get v0 from gitlab */}
					<RouteLayout exact path="/versions/v1" layout={GeneralLayout} component={HomeV1} />
					<RouteLayout exact path="/versions/v2" layout={GeneralLayout} component={HomeV2} />
					<RouteLayout exact path="/versions/v3" layout={GeneralLayout} component={HomeV3} />
					<RouteLayout exact path="/" layout={GeneralLayout} component={Home} />

					<Redirect from="/index.html" to="/" />
					<Redirect from="/sitemap.xml" to="/sitemap.xml" />
					{/* <Redirect from="/faq" to="/faqs" /> */}

					<Redirect from="/home" to="/" />
					<Redirect to="/404" />
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);
}

export default App;
