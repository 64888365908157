import React, { useEffect } from 'react';
import webinarfive from "assets/images/webinar-marketing/webinarfivenew.jpg";

const WebinarFourForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js-eu1.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "c0b03701-b918-457d-ae3e-0acea6e984e8",
                    target: '#hubspotForm'
                });
            }
        });

    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
};

const WebinarFive = () => {
    return (

        <div className=" bg-white overflow-x-hidden">
            <div>
                <div className="container marketing-container">
                    <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
                        <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                            <div className="mb-4 pt-4">
                                <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                                <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">How do I keep my best talent?</div>
                            </div>
                            <div className='mx-auto caption rounded'>
                                <div className='pb-3 lg:pr-14 rounded'>
                                    <img src={webinarfive} alt="mareting " className='w-full h-full object-contain rounded' />
                                </div>
                                <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                                    <div className='py-3'>
                                        In a post-pandemic world with low barriers to job migration, the Great Resignation has left business leaders looking for new ways to attract and retain talent.
                                            <br /> <br />
                                        Talent turnover is expensive for businesses already struggling to recover post-pandemic. So how do you mitigate against this and keep your best talents?
                                        <br /> <br />
                                        Discover effective strategies that top companies and HR leaders are using to keep their best talents in this insightful webinar with <span className='font-semibold'>Marilyn Maduka </span> - People Business Services Director for Africa, Ab-InBev.
                                  
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='py-8 lg:w-2/5 rounded' >
                            <div className="font-work-sans px-8  py-6" style={{ "minHeight": "500px", "border": "1px solid #9CA3AF" }}>
                                <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                                <WebinarFourForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WebinarFive;

