import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import logger from "utils/logger";
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Testimony from "components/common/testimony";
import { EmployerAd } from "components/common/app-ad";
import { ReactComponent as PlayVideo } from "assets/images/icons/play-circle-outline.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow-narrow-right.svg";
import { faqs } from "components/common/faq";
import ladyHoldingPhone from "assets/images/bgs/lady-holding-phone.png";
import walletEmpty from "assets/images/bgs/wallet-empty.png";
import sadEmpIllustration from "assets/images/bgs/sad-emp-illustration.png";
import fundCalculate from "assets/images/bgs/fund-calculate.png";
import employerHome from "assets/images/bgs/employer-home.png";
import employeeHome from "assets/images/bgs/employee-home.png";
import givingFeedback1 from "assets/images/bgs/giving-feedback-1.png";
import givingFeedback2 from "assets/images/bgs/giving-feedback-2.png";
import givingFeedback3 from "assets/images/bgs/giving-feedback-3.png";
import dataPrivacy from "assets/images/bgs/data-privacy.png";
import bankLevelSecurity from "assets/images/bgs/bank-level-security.png";
import introVideo from "assets/videos/intro-video.mp4";
import techCrunch from "assets/images/social/techCrunch.png";
import techCabal from "assets/images/social/techCabal.png";
import businessInsiderAfrica from "assets/images/social/businessInsiderAfrica.png";
import cnbcAfrica from "assets/images/social/cnbcAfrica.png";
import brandBluechip from "assets/images/trustedBrands/bluechip.png";
import brandBrandco from "assets/images/trustedBrands/brandco.png";
// import brandBrooksBlake from "assets/images/trustedBrands/brooks-blake.png";
import brandCuracel from "assets/images/trustedBrands/curacel.png";
import brandEden from "assets/images/trustedBrands/eden.png";
import brandSend from "assets/images/trustedBrands/send.png";
import brandThrive from "assets/images/trustedBrands/thrive.png";
import brookesAndBlake from "assets/images/trustedBrands/brooks-blake.png";
import fadac from "assets/images/trustedBrands/fadac.png";
import genesys from "assets/images/trustedBrands/genesys.png";
import roovingheight from "assets/images/trustedBrands/roving-heights.png";
import georgehouston from "assets/images/trustedBrands/george-houston.png";
// import bfree from "assets/images/trustedBrands/bfree.png";
// import lawpavillion from "assets/images/trustedBrands/lawpavlion.png";
import onepipe from "assets/images/trustedBrands/one-pipe.png";
import sabor from "assets/images/trustedBrands/sabor.png";
import venia from "assets/images/trustedBrands/venia.png";
import canaan from "assets/images/trustedBrands/canaan.png";
import xyz from "assets/images/trustedBrands/xyz.png";
import village from "assets/images/trustedBrands/village.png";
import musha from "assets/images/trustedBrands/musha.png";
import v from "assets/images/trustedBrands/v.png";
import voltron from "assets/images/trustedBrands/voltron.png";
import cileasing from "assets/images/trustedBrands/c&i-leasing.png";
import maxng from "assets/images/trustedBrands/max-ng.png";
import nutscakes from "assets/images/trustedBrands/nuts-cakes.png";
import nxt from "assets/images/trustedBrands/nxt.png";
import smartflow from "assets/images/trustedBrands/smartflow.png";
import tranex from "assets/images/trustedBrands/tranex.png";
// import phoneDownload from "assets/images/phones/phone-download.svg";
// import appStore from "assets/images/cta/app_store_x1.png";
// import playStore from "assets/images/cta/play_store.png";
import 'react-slideshow-image/dist/styles.css';


const Home = () => {
    const completeButtonRef = useRef();
    const location = useLocation();

    const [tabWhatXGet, setTabWhatXGet] = useState("companies");
    const [showIntroVideo, setShowIntroVideo] = useState(false);


    const playDemoVideo = () => {
        setShowIntroVideo((showIntroVideo) => !showIntroVideo);
        const introVideo = document.querySelector(".intro-video");
        if (introVideo !== null){
            // introVideo.setAttribute("autoPlay","");
            introVideo.currentTime = 0;
            introVideo.play();
        }
    }


    const closeModalIntroVideo = (action) => {
        const introVideo = document.querySelector(".intro-video");
        if (introVideo !== null){
            introVideo.pause();
            setShowIntroVideo(action);
        }
    }


    const toggleFaq = (index) => {
        const faq = document.querySelector(".faq-home-" + index);
        faq?.classList.toggle("faq-home-active");
    }


    useEffect(() => {
        logger("website-homepage");

        const qs = queryString.parse(location.search);
        if ((qs.referral !== undefined) && (qs.referral !== null)){
            window.location.href = process.env.REACT_APP_EMPLOYEE_APP_URL + "/get-started/referral/" + qs.referral;
        }
    }, [location])

    
    customizeSVG();


    return (
        <div>

            <Dialog open={showIntroVideo} initialFocus={completeButtonRef} onClose={() => closeModalIntroVideo(false)} className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-white opacity-95" />

                    <div className="w-full max-w-lg mx-auto relative bg-black rounded overflow-hidden">
                        <div className="h-12 px-4 bg-ep-new-gray flex justify-between overflow-hidden">
                            <div className="invisible my-auto text-white">
                                Intro Video
                            </div>
                            <div onClick={() => closeModalIntroVideo(false)} ref={completeButtonRef} className="w-7 h-7 flex my-auto border border-black group hover:bg-black rounded-md cursor-pointer">
                                <FontAwesomeIcon icon="times" className="text-black m-auto group-hover:text-white" />
                            </div>
                        </div>
                        <div>
                            <video src={introVideo} type="video/mp4" width="100%" controls className="intro-video" />
                        </div>
                    </div>
                </div>
            </Dialog>

            
            <div className="relative bg-ep-new-gray">
                <div className="container pt-0 pb-10">
                    <div className="flex justify-between">

                        <div className="mt-14 md:mt-16 lg:mt-20 xl:mt-32 w-full md:w-1/2">
                            <div className="">
                                <div className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl max-w-xl font-bold font-recoleta">
                                    Freedom from
                                    <br />
                                    monthly pay cycles!
                                </div>
                                <div className="mt-6 leading-7">
                                    Life doesn’t wait for payday, and now employees no longer have to. 
                                    <br /><br />
                                    Join 100s of companies offering their people instant access to their earned incomes with Earnipay. <span className="font-semibold">At zero cost.</span> 
                                </div>
                                <div className="mt-10 sm:flex sm:flex-wrap sm:space-x-6 md:space-x-0 lg:space-x-6 space-y-2 sm:space-y-0 md:space-y-2 lg:space-y-0">
                                    <Link to="/get-started" className="btn btn-ep-yellow btn-lg">
                                        Get started for free
                                    </Link>
                                    <button type="button" onClick={playDemoVideo} className="btn btn-transparent-black btn-lg">
                                        <PlayVideo className="w-6 h-6 customizeSVG" />
                                        <span>
                                            See how it works
                                        </span>
                                    </button>
                                </div>
                                <div className="mt-6 md:mt-10">
                                    <Link to="/personal" className="inline-block hover:underline">
                                        <div className="flex space-x-4 font-semibold">
                                            <span>
                                                Employee? Click here
                                            </span>
                                            <ArrowRight className="w-6 h-6" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="w-1/2 hidden md:inline-block lg:flex">
                            <div className="mx-auto mt-24 lg:mt-0 relative">
                                <div className="">
                                    <img src={ladyHoldingPhone} className="home-img-girl" alt="lady using earnipay" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div>
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="page-title">
                        Earnipay in the media 
                    </div>
                    <div className="mt-6 sm:mt-10 lg:mt-12">

                        <div className="max-w-5xl grid grid-cols-2 sm:grid-cols-4 gap-6 mx-auto">
                            <Link to={{pathname:"https://techcrunch.com/2022/02/17/earnipay-raises-4m-to-help-employees-in-nigeria-get-faster-access-to-their-salaries/"}} target="_blank" className="h-20 p-4 bg-ep-new-gray flex">
                                <img src={techCrunch} alt="TechCrunch" className="w-full h-full my-auto object-contain" />
                            </Link>
                            <Link to={{pathname:"https://techcabal.com/2021/12/06/earnipay-is-improving-the-financial-well-being-of-salaried-employees/"}} target="_blank" className="h-20 p-4 bg-ep-new-gray flex">
                                <img src={techCabal} alt="TechCabal" className="w-full h-full my-auto object-contain" />
                            </Link>
                            <Link to={{pathname:"https://africa.businessinsider.com/local/markets/led-by-nigerian-based-entrepreneur-nonso-onwuzulike-earnipay-receives-dollar4-million/60j6sge/"}} target="_blank" className="h-20 p-4 bg-ep-new-gray flex">
                                <img src={businessInsiderAfrica} alt="BusinessInsiderAfrica" className="w-full h-full my-auto object-contain" />
                            </Link>
                            <Link to={{pathname:"https://www.cnbcafrica.com/media/6298707228001/"}} target="_blank" className="h-20 p-4 bg-ep-new-gray flex">
                                <img src={cnbcAfrica} alt="cnbcAfrica" className="w-full h-full my-auto object-contain" />
                            </Link>
                        </div>

                    </div>
                </div>
            </div>



            <div className="pt-4 md:pt-10">
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="page-title">
                        Why Earnipay
                    </div>

                    <div className="mt-6 sm:mt-10 lg:mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 md:gap-x-14 gap-y-8 md:gap-y-14">
                    {/* <div className="mt-12 flex flex-wrap space-x-6 lg:space-x-14"> */}
                    {/* <div className="mt-12 flex flex-wrap"> */}

                        <div className="pb-14" style={{backgroundColor: "#EEF1FF"}}>
                            <div className="h-56 flex">
                                <img src={walletEmpty} className="object-fit h-full mx-auto" alt="Wallet Empty" />
                            </div>
                            <div className="mt-10 text-center px-4 lg:px-10 font-semibold">
                                42% of employees run out of cash before payday 
                            </div>
                        </div>
                        
                        <div className="pb-14" style={{backgroundColor: "#F1FFF9"}}>
                            <div className="h-56 flex">
                                <img src={sadEmpIllustration} className="object-fit h-full mx-auto" alt="Wallet Empty" />
                            </div>
                            <div className="mt-10 text-center px-4 lg:px-10 font-semibold">
                                Employers lose up to 27 days per employee per year due to money related stress.
                            </div>
                        </div>

                        <div className="pb-14" style={{backgroundColor: "#FFF1E8"}}>
                            <div className="h-56 flex justify-end">
                                <img src={fundCalculate} className="object-fit h-full" alt="Wallet Empty" />
                            </div>
                            <div className="mt-10 text-center px-4 lg:px-10 font-semibold">
                                67% of employees want more financial wellbeing support from their employers 
                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <div>
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="flex overflow-auto">
                        <div onClick={() => setTabWhatXGet("companies")} className={((tabWhatXGet === "companies") ? "tab-active" : "") + " px-5 md:px-10 py-5 whitespace-nowrap rounded-tl-lg rounded-tr-lg font-recoleta font-bold text-lg xl:text-xl opacity-40 cursor-pointer"}>
                            What Companies Get
                        </div>
                        <div onClick={() => setTabWhatXGet("employees")} className={((tabWhatXGet === "employees") ? "tab-active" : "") + " px-5 md:px-10 py-5 whitespace-nowrap rounded-tl-lg rounded-tr-lg font-recoleta font-bold text-lg xl:text-xl opacity-40 cursor-pointer"}>
                            What Employees Get
                        </div>
                    </div>
                    <div className="p-5 md:p-10" style={{backgroundColor: "#f7f9ff"}}>
                        
                        {(tabWhatXGet === "companies") &&
                            <div className="flex">
                                <div className="w-full lg:w-1/2">
                                    <div className="space-y-10 text-base">
                                        <div>
                                            <div className="font-bold">
                                                Gain a reputation as a business that cares
                                            </div>
                                            <div className="text-fade">
                                                Allowing you attract and retain top talent in an increasingly competitive employee marketplace 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                Improve your teams productivity
                                            </div>
                                            <div className="text-fade">
                                                Eliminate the financial stress that holds back even your star employees from performing optimally 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                Zero cost to your business:
                                            </div>
                                            <div className="text-fade">
                                                Offer Earnipay to your employees at no cost to you. In fact, we're saving you money and resources spent on salary advances and loan grants 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                No changes to your payroll processes
                                            </div>
                                            <div className="text-fade">
                                                We'll work with your existing systems, giving you full control and oversight through our customized employer portal. 
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap">
                                            <Link to={{pathname: "https://meetings-eu1.hubspot.com/busayo-onome/marketing"}} target="_blank" rel="noreferrer" className="mb-2 mr-4 btn btn-ep-yellow btn-lg">
                                                Schedule a demo
                                            </Link>
                                            <Link to="/business" className="mb-2 mr-4 btn btn-transparent-black btn-lg">
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 hidden lg:flex justify-end">
                                    <div className="my-auto flex">
                                        <img src={employerHome} className="object-fit max-w-full mx-auto" alt="Employer" />
                                    </div>
                                </div>
                            </div>
                        }

                        {(tabWhatXGet === "employees") &&
                            <div className="flex">
                                <div className="w-full lg:w-1/2">
                                    <div className="space-y-10 text-base">
                                        <div>
                                            <div className="font-bold">
                                                Your livelihood in your hands
                                            </div>
                                            <div className="text-fade">
                                                Responsible access to your income and reduced reliance on payday loans and debt cycles 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                Control over plans
                                            </div>
                                            <div className="text-fade">
                                                So you can budget, track, save and make better financial choices
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                Zero interest rates
                                            </div>
                                            <div className="text-fade">
                                                You pay a small upfront processing fee 
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">
                                                Easy access
                                            </div>
                                            <div className="text-fade">
                                                Get paid within minutes via our mobile app, web app or USSD
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap">
                                            <Link to="/refer-employer" className="mb-2 mr-4 btn btn-ep-yellow btn-lg">
                                                Refer your employer
                                            </Link>
                                            <Link to="/personal" className="mb-2 mr-4 btn btn-transparent-black btn-lg">
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2 hidden lg:flex justify-end">
                                    <div className="my-auto flex h-80">
                                        <img src={employeeHome} className="object-fit h-full max-w-full mx-auto" alt="Employer" />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>




            <div>
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="page-title">
                        <div>
                            Great for Employers, 
                        </div>
                        <div className="text-ep-new-blue">
                            Loved By Employees
                        </div>
                    </div>
                    <div className="mt-6 sm:mt-10 lg:mt-12">

                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <div className="flex px-6 sm:px-3 md:px-6 py-4 sm:py-8 space-x-2 rounded border bg-ep-new-gray">
                                <div className="my-auto">
                                    <img src={givingFeedback1} className="object-fit w-64" alt="lady using earnipay" />
                                </div>
                                <div className="my-auto text-base">
                                    <span className="text-ep-new-blue font-bold">5 out of 5</span>
                                    &nbsp;employees say that they are financially better off with Earnipay 
                                </div>
                            </div>
                            <div className="flex px-6 sm:px-3 md:px-6 py-4 sm:py-8 space-x-2 rounded border bg-ep-new-gray">
                                <div className="my-auto">
                                    <img src={givingFeedback2} className="object-fit w-64" alt="lady using earnipay" />
                                </div>
                                <div className="my-auto text-base">
                                    <span className="text-ep-new-blue font-bold">92.7%</span>
                                    &nbsp;of employees say that they would recommend Earnipay to colleagues
                                </div>
                            </div>
                            <div className="flex px-6 sm:px-3 md:px-6 py-4 sm:py-8 space-x-2 rounded border bg-ep-new-gray">
                                <div className="my-auto">
                                    <img src={givingFeedback3} className="object-fit w-64" alt="lady using earnipay" />
                                </div>
                                <div className="my-auto text-base">
                                    <span className="text-ep-new-blue font-bold">10 in 10</span>
                                    &nbsp;employers say that integrating with Earnipay was hassle free 
                                </div>
                            </div>
                        </div>

                        <div className="w-full h-px mt-6 border opacity-50"></div>

                        <div className="mt-6 px-0 xl:px-20">
                            <div className="lg:flex lg:space-x-6 space-y-6 lg:space-y-0">
                                <div className="w-full lg:w-1/2">
                                    <div className="flex px-6 py-8 space-x-2 rounded border bg-ep-new-gray">
                                        <div className="my-auto">
                                            <img src={dataPrivacy} className="object-fit" alt="lady using earnipay" style={{width: "36rem"}} />
                                        </div>
                                        <div className="my-auto text-base">
                                            <div className="font-bold">
                                                Data Privacy
                                            </div>
                                            <div>
                                                We prioritize protecting your data. Our secure servers ensure that no third-party is granted access to your personal information. We collect and use your personal data to constantly improve your user experience
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2">
                                    <div className="flex px-6 py-8 space-x-2 rounded border bg-ep-new-gray">
                                        <div className="my-auto">
                                            <img src={bankLevelSecurity} className="object-fit" alt="lady using earnipay" style={{width: "36rem"}} />
                                        </div>
                                        <div className="my-auto text-base">
                                            <div className="font-bold">
                                                Bank Level Security
                                            </div>
                                            <div>
                                                All application traffic is encryppted and protected by using 256-bit AES bank level encryption. This provides security between devices and our servers ensuring personal and transactional details are always kept private
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            
            <div>
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="page-title">
                        <div>
                            100s of companies already trust Earnipay to improve their workforce productivity.
                        </div>
                        <div className="text-ep-new-blue">
                            Join them.
                        </div>
                    </div>
                    <div className="mt-6 sm:mt-10 lg:mt-14">
                        <div className="max-w-4xl mx-auto">

                            <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-10">
                                <div className="h-10 flex">
                                    <img src={brandBluechip} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={brandEden} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                {/* 
                                <div className="h-10 flex">
                                    <img src={brandBrooksBlake} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                */}
                                <div className="h-10 flex">
                                    <img src={brandSend} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={brandThrive} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={brandBrandco} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={brandCuracel} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={onepipe} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={fadac} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={genesys} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={georgehouston} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={roovingheight} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={venia} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={sabor} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="flex align-middle justify-center relative">
                                    <img src={brookesAndBlake} alt="Trusted Brands" className="w-16 h-16 my-auto object-contain absolute" style={{"bottom": "-15px"}} />
                                </div>
                                <div className="h-10 flex">
                                    <img src={cileasing} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={maxng} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={nutscakes} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={nxt} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 lg:flex hidden md:block">
                                    {/* <img src={lawpavillion} alt="Trusted Brands" className="w-full h-full my-auto object-contain" /> */}
                                </div>
                                <div className="h-10 lg:flex hidden md:block">
                                    {/* <img src={lawpavillion} alt="Trusted Brands" className="w-full h-full my-auto object-contain" /> */}
                                </div>
                                <div className="h-10 flex">
                                    <img src={smartflow} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="h-10 flex">
                                    <img src={tranex} alt="Trusted Brands" className="w-full h-full my-auto object-contain" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex mt-16">
                        <Link to="/get-started" className="btn btn-ep-yellow btn-lg mx-auto">
                            Interested? Click here
                        </Link> 
                    </div>
                </div>
            </div>



            <div className="mt-14 sm:mt-20 lg:mt-28"></div>



            <Testimony view={["employer","employee"]} />

            

            
            <div>
                <div className="container mt-14 sm:mt-20 lg:mt-28">
                    <div className="page-title">
                        <div>
                            Our Investors
                        </div>
                    </div>
                    <div className="mt-6 sm:mt-10 lg:mt-14">
                        <div className="max-w-4xl mx-auto">

                            {/* <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-10 gap-y-6"> */}
                            <div className="flex flex-wrap justify-center">
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={canaan} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={xyz} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={village} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={musha} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={v} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                                <div className="w-32 sm:w-auto h-20 flex mx-4 mb-4">
                                    <img src={voltron} alt="Our Investors" className="w-full h-full my-auto object-contain" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




            <div className="bg-ep-new-gray">
                <div className="container mt-14 sm:mt-20 lg:mt-24 py-14 sm:py-20 lg:py-28">
                    <div className="page-title">
                        FAQs
                    </div>

                    <div className="mt-6 sm:mt-10 lg:mt-14">
                        <div className="space-y-5 mt-4">

                            {faqs.filter(faq => faq.category === 'About Earnipay').map((faq, index) =>
                                <div className={"faq-home bg-white border rounded-md overflow-hidden faq-home-"+index} key={index}>
                                    <div className="px-6 py-5 flex justify-between cursor-pointer hover:bg-gray-200" onClick={()=>toggleFaq(index)}>
                                        <div className="font-semibold font-recoleta mr-10 faq-home-question">
                                            {faq.question}
                                        </div>
                                        <FontAwesomeIcon icon="plus" className="my-auto faq-home-plus" />
                                        <FontAwesomeIcon icon="minus" className="my-auto faq-home-minus" />
                                    </div>
                                    <div className="p-6 pt-0 text-base leading-6 hidden faq-home-answer">
                                        {faq.answer}
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="flex mt-16">
                        <Link to="/faqs" className="btn btn-ep-yellow btn-lg mx-auto">
                            Want to know more? Click here
                        </Link>
                    </div>
                </div>
            </div>



            
            <EmployerAd />




        </div>
    )
}

export default Home;
