import React, {useEffect} from 'react';
import guideUltimateJobOfferChecklist from "assets/images/webinar-marketing/guideUltimateJobOfferChecklist.jpg";

const GuideForm = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src='https://js-eu1.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
          if (window.hbspt) {
              window.hbspt.forms.create({
                  region: "eu1",
                  portalId: '25087743',
                  formId: "56f9486d-de91-4029-a783-353076f1aa4d",
                  target: '#hubspotForm'
              });
          }
      });
  
  }, []);

return (
  <div>
      <div id="hubspotForm"></div>
  </div>
  )
};

const Guide = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>CHECKLIST</h5>
                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">The Ultimate Job Offer Checklist</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded'>
                    <img src={guideUltimateJobOfferChecklist} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>
                      A good job offer comes with a lot of positives - higher salary, better work environment and more.
                      <br /> <br />
                      While nodding and saying yes to an offer would help you reach your dream job, how do you know if the job offer is right for you?
                      It is important to remember that a good job entails more than just signing on the dotted line.
                      <br /> <br />
                      We have created a FREE checklist to help you get all the core information you need to make the right decision.
                    </div>
                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6 border border-gray-200 shadow-lg rounded-lg" style={{"minHeight":"500px"}}>
                  <h2 className="pb-8 pt-4 text-base md:text-lg lg:text-2xl font-recoleta font-semibold">Download Your Checklist Now</h2>
                  <GuideForm
                      region='eu1'
                      portalId= '25087743'
                      formId='56f9486d-de91-4029-a783-353076f1aa4d'
                    />
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default Guide
