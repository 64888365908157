import React from 'react';
import webinar from "assets/images/features/webinar1.jpg";
import WebinarOneForm from './WebinarOneForm';

const  MarketingForm = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">Should women play more office politics? Survival skills for women.</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded'>
                    <img src={webinar} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>
                    Surveys show that women are more likely to shun office politics, believing that their work should speak for them. While office politics may get a bad rap, political behavior in the workplace is often the basis upon which many people receive opportunities for advancement.
                    <br /> <br />
                    Any real change we hope to achieve needs to begin with changing the perception of what office politics means, and how women can get more comfortable with the notion of power and wielding influence.

                    <br /><br />
                    We’re bringing Funke Onafuye (CEO, CV Loft), Busayo Oyetunji (COO, Earnipay) and Kemi Onabanjo (Associate Partner, McKinsey) to discuss this topic.
                    </div>

                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6" style={{"minHeight":"500px", "border":"1px solid #9CA3AF"}}>
                  <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                  <WebinarOneForm/>
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default MarketingForm;


