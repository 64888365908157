import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo-white.svg";
import DynamicHeadTag from "components/misc/DynamicHeadTag";
import googleAnalyticsTrack from "utils/googleAnalyticsTrack";

const BookDemo = () => {

	useEffect(() => {
        googleAnalyticsTrack();
    }, [])

	return (
		<div className="min-h-screen bg-ep-primary-600 bg-waves relative overflow-auto">

			<DynamicHeadTag
				headerText="Contact Us | Earnipay"
				headerDescription="Contact us to find out how Earnipay can solve your Finance and People operations challenges"
			/>
			
			<div className="h-16 md:h-20 md:fixed z-50 top-0 left-0 right-0 flex">
				<div className="container my-auto">
					<div className="flex justify-between">
						<Link to="/" className="h-10 p-1 flex-shrink-0 flex">
							<img
								src={logo}
								alt="Earnipay logo"
								className="my-auto h-full object-cover"
							/>
						</Link>
					</div>
				</div>
			</div>

			<div className="container mt-14 md:mt-36 mb-24 md:flex md:justify-between relative">
				<div className="md:container md:flex md:justify-between md:fixed md:z-10 md:top-36 md:left-[50%] transform md:translate-x-[-50%]">
					<div className="w-full md:w-1/2 md:pr-10">
						<div className="text-3xl sm:text-4xl lg:text-5xl text-ep-primary-light font-bold font-aeonik">
							Find out how Earnipay <br className="hidden xl:block" /> can solve
							your Finance <br className="hidden xl:block" /> and People
							operations <br className="hidden xl:block" /> challenges
						</div>
						<div className="mt-10 md:mt-28 text-base">
							<div className="text-[#A4EDD8]">
								Don’t want to fill out a form, you can send an email to{" "}
								<br className="hidden sm:block" />{" "}
								<a
									href="mailto:sales@earnipay.com"
									target="_blank"
									rel="noreferrer"
									className="text-white hover:underline">
									sales@earnipay.com
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full md:w-1/2"></div>
				<div className="w-full md:w-1/2 md:pl-10 mt-14 md:mt-0 relative z-20">
					<div className="max-w-lg min-h-[400px] mx-auto p-8 bg-white rounded-2xl shadow-lg overflow-y-auto">
							<iframe title="form" width='100%' height='1260px' 
							src='https://bigin.zoho.com/crm/WebFormServeServlet?rid=ecdf7c5061840502a1c71e4e2bc41ed1eb2bd36ca6726341a06d52b25e4550edgideab87bc09e27c31b4b04d80936a517fc616222c21166f28a676570beb5cbc798'>
							</iframe>
					</div>
				</div>
			</div>
		</div>
	);
};


export default BookDemo;
