import React from 'react';
import isReactFragment from "utils/isReactFragment";
import { Menu } from "@headlessui/react";
import { ReactComponent as IconNav } from "assets/images/icons/dots-v.svg";


const Dropdown = (props) => {
    // console.log(props.children[0].type);
    // console.log(isReactFragment(props.children[0].type) ? "a" : "b");

    return (
        <Menu as="div">
            <div className={"dropdownNew group inline-block " + props.className}>

                <Menu.Button as="div" className="cursor-pointer">
                    {(isReactFragment(props.children[0].type)) &&
                        <IconNav style={{ height: "26px" }} className="w-8 px-1.5 py-0.5 relative cursor-pointer group-hover:bg-gray-300 rounded" />
                    }

                    {!(isReactFragment(props.children[0].type)) &&
                        props.children[0]
                    }
                </Menu.Button>

                <Menu.Items as="div" className="dropdown" style={{ maxWidth: "none", width: "90%", marginLeft: "5%" }}>
                    <div className="dropdown-content">
                        {(props.children) ? props.children[1] : ""}
                    </div>
                </Menu.Items>

            </div>
        </Menu>
    );
};

export default Dropdown;