import React, {useEffect} from 'react';
import webinarthree from "assets/images/webinar-marketing/webinar-three.jpg";

const WebinarThreeForm = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src='https://js-eu1.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      console.log(document.body)
      script.addEventListener('load', () => {
          if (window.hbspt) {
              window.hbspt.forms.create({
                  region: "eu1",
                  portalId: '25087743',
                  formId: "f0475ad5-bf6d-40c1-8d56-71539a17995a",
                  target: '#hubspotForm'
              });
          }
      });
  
  }, []);

return (
  <div>
      <div id="hubspotForm"></div>
  </div>
  )
};

const  WebinarThree = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">How to keep your employees motivated even in tough times</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded'>
                    <img src={webinarthree} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>
                      Talent recruitment and retention, waning office culture, remote work and the rising cost of living are some of the biggest challenges facing businesses in a post-pandemic world.
                      <br /> <br />
                      But the single most urgent threat to a business’ success — and potentially the most damaging — is the deterioration of employee motivation.

                      Keeping teams happy, engaged and motivated is hard work that's critical to the success of any business.
                      <br /> <br />
                      Join <span className='font-semibold'>Kemi Onabanjo,</span> Associate Partner, McKinsey 	&#38; President, Covenant University Alumni Association, as she shares winning strategies to keep employees motivated in tough times and what this could mean for your bottomline.
                    </div>

                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6" style={{"minHeight":"500px", "border":"1px solid #9CA3AF"}}>
                  <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                  <WebinarThreeForm/>
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default WebinarThree;

