import React, {useEffect} from 'react';
import finHealthImage from "assets/images/webinar-marketing/finHealth.jpg";

const GuideFinHealthForm = () => {
  useEffect(() => {
      const script = document.createElement('script');
      script.src='https://js-eu1.hsforms.net/forms/shell.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
          if (window.hbspt) {
              window.hbspt.forms.create({
                  region: "eu1",
                  portalId: '25087743',
                  formId: "6677ba1f-8765-4204-bb11-4c4a397a19c5",
                  target: '#hubspotForm'
              });
          }
      });
  
  }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );
};

const  GuideFinHealth = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>GUIDE</h5>
                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">Improving the financial health of your employees</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded'>
                    <img src={finHealthImage} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'> Financial wellness is not often discussed, especially among employees and their managers/business owners. Everyone desires to make money but may not understand the fundamentals of building financial wealth. This is why we see employees living paycheck to paycheck and less productive due to financial stress.
                    <br /> <br />
                    Download our guide on Improving the financial health of your employees to see how business owners/HR managers can intervene to ensure their employees are financially stable to put in their best work and improve the overall growth of the business. </div>
                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6 bg-ep-yellow" style={{"minHeight":"500px"}}>
                  <h2 className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Download Your Guide Now</h2>
                  <GuideFinHealthForm
                      region='eu1'
                      portalId= '25087743'
                      formId='cb7311d5-20fd-4d98-a53b-dbd063b448f7'
                    />
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default GuideFinHealth;

