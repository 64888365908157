// from https://www.npmjs.com/package/typed.js

import React, { Component } from 'react';
import Typed from 'typed.js';


class TypedReact extends Component {
    componentDidMount() {
        // If you want to pass more options as props, simply add
        // your desired props to this destructuring assignment.
        const { strings } = this.props;
        
        // const defautColor = "gray";
        const newColor = [
            "yellow",
            "green",
            "blue",
        ]

        // You can pass other options here, such as typing speed, back speed, etc.
        const options = {
            strings: strings,
            typeSpeed: 50,
            backSpeed: 50,
            backDelay: 3000,
            fadeOut: true,
            fadeOutClass: 'typed-fade-out',
            fadeOutDelay: 500,
            loop: true,
            loopCount: Infinity,

            preStringTyped: (arrayPosition, self) => {

                // console.log('prestring');
                // console.log(arrayPosition);

                let previousColor = newColor[(arrayPosition-1)];
                let currentColor = newColor[arrayPosition];
                if (arrayPosition === 0){
                    previousColor = newColor[((newColor.length)-1)];
                    // console.log("index at zero; " + previousColor);
                }

                // console.log("previousColor " + previousColor);
                // console.log("currentColor " + currentColor);

                const colorTexts400 = document.querySelectorAll(".typed-home-text-color-400");
                colorTexts400.forEach(function(colorText400) {
                    colorText400?.classList.remove("text-" + previousColor + "-400");
                    colorText400?.classList.add("text-" + currentColor + "-400");
                });

                const colorBgs100 = document.querySelectorAll(".typed-home-bg-color-100");
                colorBgs100.forEach(function(colorBg100) {
                    colorBg100?.classList.remove("bg-" + previousColor + "-100");
                    colorBg100?.classList.add("bg-" + currentColor + "-100");
                });

                const colorBgs300 = document.querySelectorAll(".typed-home-bg-color-300");
                colorBgs300.forEach(function(colorBg300) {
                    colorBg300?.classList.remove("bg-" + previousColor + "-300");
                    colorBg300?.classList.add("bg-" + currentColor + "-300");
                });

                const colorBorders100 = document.querySelectorAll(".typed-home-border-color-100");
                colorBorders100.forEach(function(colorBorder100) {
                    colorBorder100?.classList.remove("border-" + previousColor + "-100");
                    colorBorder100?.classList.add("border-" + currentColor + "-100");
                });

                const colorBorders300 = document.querySelectorAll(".typed-home-border-color-300");
                colorBorders300.forEach(function(colorBorder300) {
                    colorBorder300?.classList.remove("border-" + previousColor + "-300");
                    colorBorder300?.classList.add("border-" + currentColor + "-300");
                });

            },
        };

        // this.el refers to the <span> in the render() method
        this.typed = new Typed(this.el, options);
    }

    componentWillUnmount() {
        // Make sure to destroy Typed instance on unmounting
        // to prevent memory leaks
        this.typed.destroy();
    }

    render() {
        return (
            <>
                <span
                    ref={(el) => { this.el = el; }}
                />

                {/* 
                    <div className="wrap">
                        <h1>Typed.js</h1>
                        <div className="typed-react">
                            <span
                            style={{ whiteSpace: 'pre' }}
                            ref={(el) => { this.el = el; }}
                            />
                        </div>
                        <button onClick={() => this.typed.toggle()}>Toggle</button>
                        <button onClick={() => this.typed.start()}>Start</button>
                        <button onClick={() => this.typed.stop()}>Stop</button>
                        <button onClick={() => this.typed.reset()}>Reset</button>
                        <button onClick={() => this.typed.destroy()}>Destroy</button>
                    </div>
                */}
            </>
        );
    }
}

export default TypedReact;