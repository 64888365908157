import React, { Component } from "react";
import logo from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import laptopTablet from "assets/images/bgs/laptop-tablet.png";
import laptopMobile from "assets/images/bgs/laptop-mobile.png";
import phoneDownload from "assets/images/phones/phone-download.svg";
import appStore from "assets/images/cta/download-app-store.png";
import playStore from "assets/images/cta/download-play-store.png";

export class MobileAd extends Component {

    render() {

        return (
            <div className="relative bg-ep-new-gray overflow-auto">
                <div className="container mt-16 lg:mt-6 relative z-10">
                    <div className="lg:flex lg:justify-between max-w-5xl mx-auto">
                        <div className="lg:mt-16 text-center">
                            <div className="font-semibold">
                                Download the Earnipay app  
                            </div>
                            <div className="flex space-x-4 mt-3 justify-center lg:justify-start">
                                <Link to={{pathname:"https://apps.apple.com/ng/app/earnipay/id1582293975"}} onClick={(e) => {window.open("https://apps.apple.com/ng/app/earnipay/id1582293975", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block">
                                    <img src={appStore} className="cursor-pointer h-10 sm:h-12" alt="apple appstore" />
                                </Link>
                                <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.open("https://play.google.com/store/apps/details?id=com.earnipay.earnipay", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block">
                                    <img src={playStore} className="cursor-pointer h-10 sm:h-12" alt="google playstore" />
                                </Link>
                            </div>
                            <div className="mt-6 font-semibold">
                                Access through USSD
                            </div>
                            <Link className="mt-2 text-4xl lg:text-5xl font-bold" style={{color: "#416FF4"}} to={{pathname:"tel:*347*729#"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }}>
                                *347*729#
                            </Link>
                            <div className="mt-6 flex">
                                <Link to={{pathname: process.env.REACT_APP_EMPLOYEE_APP_URL}} target="_blank" rel="noreferrer" className="btn btn-ep-yellow btn-lg mx-auto">
                                    Log into your earnipay account
                                </Link>
                            </div>
                        </div>
                        <div className="mt-10 pb-0 md:h-80 mx-auto lg:mx-0 flex max-w-lg">
                            <img src={phoneDownload} className="mx-auto h-full object-contain" alt="phoneDownload" />
                        </div>
                    </div>
                </div>
                <div className="bg-img-under bg-terrain"></div>
            </div>
        );
    }
}



export class EmployeeAd extends Component {

    render() {

        return (
            <>

                {/* <div className="relative bg-ep-new-gray overflow-auto"> */}
                <div className="relative bg-white overflow-auto">
                    <div className="container relative z-10">
                        <div className="py-12 lg:py-6 lg:flex lg:justify-between space-y-6 lg:space-y-0">
                            <div className="max-w-sm mx-auto lg:mx-0 my-auto text-center lg:text-left">
                                <div className="h-10">
                                    <img src={logo} className="h-full object-contain mx-auto lg:mx-0" alt="earnipay logo" />
                                </div>
                                <div className="mt-10 font-semibold text-xl">
                                    Access through USSD
                                </div>
                                <Link className="mt-2 text-4xl lg:text-5xl font-black text-ep-new-blue inline-block" to={{pathname:"tel:*347*729#"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }}>
                                    *347*729#
                                </Link>
                                <div className="mt-6 flex">
                                    <Link to={{pathname: process.env.REACT_APP_EMPLOYEE_APP_URL}} target="_blank" rel="noreferrer" className="btn btn-ep-yellow btn-lg mx-auto">
                                        Log into your earnipay account
                                    </Link>
                                </div>
                            </div>
                            <div className="">
                                <img src={laptopMobile} className="mx-auto max-h-80 object-contain" alt="phoneDownload" />
                            </div>
                            <div className="lg:flex lg:items-end">
                                <div className="relative lg:-top-10 text-center lg:text-left">
                                    <div className="font-semibold">
                                        Download the Earnipay app  
                                    </div>
                                    <div className="flex space-x-4 mt-3 justify-center lg:justify-start">
                                        <Link to={{pathname:"https://apps.apple.com/ng/app/earnipay/id1582293975"}} onClick={(e) => {window.open("https://apps.apple.com/ng/app/earnipay/id1582293975", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block">
                                            <img src={appStore} className="cursor-pointer h-10 sm:h-10" alt="apple appstore" />
                                        </Link>
                                        <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.open("https://play.google.com/store/apps/details?id=com.earnipay.earnipay", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block">
                                            <img src={playStore} className="cursor-pointer h-10 sm:h-10" alt="google playstore" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-img-under bg-terrain"></div> */}
                </div>
                
            </>
        );
    }
}


export class EmployerAd extends Component {

    render() {
        return (
            <>

                {/* <div className="relative bg-ep-new-gray overflow-auto"> */}
                <div className="relative bg-white overflow-auto">
                    <div className="container relative z-10">
                        <div className="py-12 lg:py-6 lg:flex lg:justify-between space-y-6 lg:space-y-0">
                            <div className="max-w-sm mx-auto lg:mx-0 my-auto text-center lg:text-left">
                                <div className="h-10">
                                    <img src={logo} className="h-full object-contain mx-auto lg:mx-0" alt="earnipay logo" />
                                </div>
                                <div className="mt-10 font-semibold">
                                    Access the all-in-one dashboard to offer financial wellness to your employees
                                </div>
                                <div className="mt-6 flex space-x-4 justify-center lg:justify-start">
                                    <Link to={{pathname: "https://business.earnipay.com"}} target="_blank" rel="noreferrer" className="btn bg-ep-new-blue btn-lg text-white">
                                        Sign In
                                    </Link>
                                    <Link to={{pathname: "https://business.earnipay.com/onboarding/get-started"}} target="_blank" rel="noreferrer" className="btn btn-ep-yellow btn-lg">
                                        Sign Up
                                    </Link>
                                </div>
                            </div>
                            <div className="">
                                <img src={laptopTablet} className="mx-auto max-h-80 object-contain" alt="phoneDownload" />
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-img-under bg-terrain"></div> */}
                </div>
                
            </>
        );
    }
}
