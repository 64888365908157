
import React, {useEffect} from "react";

    const HubspotContactForm = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js-eu1.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: 'ac845888-8fe8-45bc-8a38-ffe3a3ca8d58',
                    target: '#hubspotForm'
                });
            }
        });
       
    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );

}

export default HubspotContactForm;

