import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
    return (
        <div>

            <div className="pt-12 pb-20 px-4">
                <div>
                    <div className="my-6 font-semibold text-2xl md:text-3xl text-center">Privacy Policy</div>
                    <div className="terms md:px-32">

                        <ol className="parent-ol">
                            <li className="parent-li">
                                <div className="terms-title">
                                    INTRODUCTION
                                </div>
                                <div>
                                    It is Earnipay's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to earnipay.com/ (hereinafter, "us", "we", or "earnipay.com/"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
                                    <br /><br />
                                    This Privacy Policy, together with the Terms of service posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms of service.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    INFORMATION WE COLLECT AND PROCESS ABOUT OUR VISITORS
                                </div>

                                    <div>
                                            We may collect and process the following personal data about our visitors:                                    
                                            
                                            <ol className="parent-ol">
                                            <li className="parent-li terms-title">
                                            Personal Data
                                            </li>
                                            
                                            The   data   we   collect   about   our   visitors.   A   visitor   may   give   us information   about   himself
                                            when   he   registers   with   our   website
                                            earnipay.com  or our Mobile Application or by communicating with us by
                                            phone,   email   or   otherwise.   The   information     given   to   us   may   include
                                            name,   address,   email   address,   phone   numbers,   date   of   birth,   Bank
                                            verification   number   and   identity   documents.   This   may   include   financial
                                            data relating to a visitor’s means and method of payment such as bank
                                            and card details.  it may include salary, job title and company information.
                                            If   visitors   engage   with   us   through   social   media,   then   this   may   include
                                            visitors’ social media contact data
                                            In   each   case,   Earnipay   collects   such   information   only   insofar   as   is
                                            necessary or appropriate to fulfill the purpose of the visitor's interaction
                                            with   Earnipay.   Earnipay   does   not   disclose   personally-identifying
                                            information other than as described below. And visitors can always refuse
                                            to supply personally-identifying information, with the caveat that it may
                                            prevent them from engaging in certain website-related activities.
                                            </ol>
                                                </div>

                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    WEBSITE VISITORS
                                </div>
                                <div>
                                    Like most website operators, Earnipay collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Earnipay's purpose in collecting non-personally identifying information is to better understand how Earnipay's visitors use its website. From time to time, Earnipay may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
                                    <br /><br />
                                    Earnpay also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on <Link to="/" style={{ "paddingLeft": "0" }}>https://earnipay.com/</Link> blog posts. Earnipay only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    PERSONALLY-IDENTIFYING INFORMATION
                                </div>
                                <div>
                                    Certain visitors to Earnipay's websites choose to interact with Earnipay in ways that require Earnipay to gather personally-identifying information. The amount and type of information that Earnipay gathers depends on the nature of the interaction. For example, we ask visitors who leave a comment at https://earnipay.com/ to provide a username and email address.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    SECURITY
                                </div>
                                <div>
                                    The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    ADVERTISEMENTS
                                </div>
                                <div>
                                    Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Earnipay and does not cover the use of cookies by any advertisers.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    LINKS TO EXTERNAL SITES
                                </div>
                                <div>
                                    Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms of service of every site you visit.
                                    <br /><br />
                                    We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    EARNPAY USES GOOGLE ADWORDS FOR REMARKETING
                                </div>
                                <div>
                                    Earnipay uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone's past visits. Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy.
                                    <br /><br />
                                    You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    PROTECTION OF CERTAIN PERSONALLY-IDENTIFYING INFORMATION
                                </div>
                                <div>
                                    Earnipay discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Earnipay's behalf or to provide services available at Earnipay's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Earnipay's website, you consent to the transfer of such information to them. Earnipay will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Earnipay discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Earnipay believes in good faith that disclosure is reasonably necessary to protect the property or rights of Earnipay, third parties or the public at large.
                                    <br /><br />
                                    If you are a registered user of https://earnipay.com/ and have supplied your email address, Earnipay may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Earnipay and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Earnipay takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                PURPOSES   AND   LEGAL   BASIS   FOR   USING   AND   COLLECTING EARNIPAYS’ USERS PERSONAL DATA
                                </div>
                                    <div>
                                        <ol className="parent-ol">
                                            <li className="parent-li"> 
                                            Earnipay uses personal data we hold about visitor’s to pursue their
                                            legitimate interests in providing and marketing their products and services
                                            to users/visitors.
                                            </li>

                                            <li className="parent-li">
                                            The   personal   data   we   hold   will   also   be   used   in   performing
                                            our contract with users and providing our product or service.
                                            </li>

                                            <li className="parent-li">
                                            We will only use visitor’s data for the purposes which we collected
                                            it,   unless   we   reasonably   consider   that   we   need   to   use   it   for   another
                                            reason and that reason is compatible with the original purpose.
                                            </li>

                                            <li className="parent-li">
                                            In some cases, we may also use the personal data we hold about
                                            our   users   to       comply   with   our   legal   obligations,   or   enter   into   or   perform   a
                                            contract with users.
                                            </li>

                                            <li className="parent-li">
                                            Where   we   have   your   consent,   we   may   also   send   you   direct
                                            marketing communications.
                                            </li>

                                        </ol>
                                    </div>
                            </li>
                            

                            <li className="parent-li">
                                <div className="terms-title">
                                    AGGREGATED STATISTICS
                                </div>
                                <div>
                                    Earnipay may collect statistics about the behavior of visitors to its website. Earnipay may display this information publicly or provide it to others. However, Earnipay does not disclose your personally-identifying information.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    COOKIES
                                </div>
                                <div>
                                    To enrich and perfect your online experience, Earnipay uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
                                    <br /><br />
                                    A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Earnipay uses cookies to help Earnipay identify and track visitors, their usage of https://earnipay.com/, and their website access preferences. Earnipay visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Earnipay's websites, with the drawback that certain features of Earnipay's websites may not function properly without the aid of cookies.
                                    <br /><br />
                                    By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Earnipay's use of cookies.
                                </div>
                            </li>


                            <li className="parent-li">
                                <div className="terms-title">
                                FACE DATA POLICY
                                </div>
                                <div>
                                To ensure the security of user’s account, Earnipay may collect and process face data. 
                                The face data may be used for authentication purposes or personalizing user experience 
                                within our services,providing tailored content or features. 
                                Earnipay may share your face data with third- party service providers that assist us in delivering 
                                and improving our services. These providers are contractually bound to protect your data and can 
                                only use it for specified purposes.
                                We retain your face data only for as long as necessary for the purposes outlined in this privacy policy.
                                </div>
                            </li>

                            

                            <li className="parent-li">
                                <div className="terms-title">
                                    E-COMMERCE
                                </div>
                                <div>
                                    Those who engage in transactions with Earnipay – by purchasing Earnipay's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Earnipay collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Earnipay. Earnipay does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    BUSINESS TRANSFERS
                                </div>
                                <div>
                                    If Earnipay, or substantially all of its assets, were acquired, or in the unlikely event that Earnipay goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Earnipay may continue to use your personal information as set forth in this policy.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    PRIVACY POLICY CHANGES
                                </div>
                                <div>
                                    Although most changes are likely to be minor, Earnipay may change its Privacy Policy from time to time, and at Earnipay's sole discretion. Earnipay encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    CHANGES TO THIS PRIVACY POLICY
                                </div>
                                <div>
                                    In compliance with changing regulations and laws, Earnipay may update its Privacy Policy from time to time. If and when it does, it shall notify data subjects via email or display a prominent notice on the website, prior to the change becoming effective. Earnipay shall ensure to review this Policy periodically for updates. Changes to this Privacy Policy are effective once it is posted on this page.
                                </div>
                            </li>

                            <li className="parent-li">
                                <div className="terms-title">
                                    CONTACT INFORMATION
                                </div>
                                <div>
                                    For any questions about this Privacy Policy, please contact Earnipay via: <Link to={{ pathname: "mailto:hello@earnipay.com" }} className="text-ep-primary-400 hover:underline" onClick={(e) => { window.location = e.target.getAttribute("href"); e.preventDefault(); }}>hello@earnipay.com</Link>.
                                </div>
                            </li>
                        </ol>
                        <p className="mb-0 mt-14 text-gray-500 text-sm italic">
                            Last Updated on <span className="font-medium">6th February, 2024.</span>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Privacy;
