import mixpanel from 'mixpanel-browser';

const logger = (event) => {

    try {
        // import mixpanel from 'mixpanel-browser';
        // or with require() syntax:
        // const mixpanel = require('mixpanel-browser');
        // Enabling the debug mode flag is useful during implementation,
        // but it's recommended you remove it for production

        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
        mixpanel.init(mixpanelToken, { debug: false });
        mixpanel.track(event);

        return true;
    }
    catch (e) {
        // console.log("logger error: ", e);
        return false;
    }

};

export default logger;