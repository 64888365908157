import React, { useEffect } from 'react';
import webinarfour from "assets/images/webinar-marketing/webinarfour.jpg";

const WebinarFourForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js-eu1.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "3ada2245-bf58-4fc4-ae8a-745dfff81dd0",
                    target: '#hubspotForm'
                });
            }
        });

    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
};

const WebinarFour = () => {
    return (

        <div className=" bg-white overflow-x-hidden">
            <div>
                <div className="container marketing-container">
                    <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
                        <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                            <div className="mb-4 pt-4">
                                <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                                <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">How to build a compelling talent attraction strategy</div>
                            </div>
                            <div className='mx-auto caption rounded'>
                                <div className='pb-3 lg:pr-14 rounded'>
                                    <img src={webinarfour} alt="mareting " className='w-full h-full object-contain rounded' />
                                </div>
                                <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                                    <div className='py-3'>
                                        The market for talent is hotter than it's ever been! With the high rate of emigration and as more and more industries and startups take flight, hiring the best is what will separate winning companies from the competition.
                                        <br /> <br />
                                        Simply advertising vacancies no longer cut it and talent professionals now need smart strategies to ensure the company is ahead of the talent attraction game.
                                        <br /> <br />
                                        Join <span className='font-semibold'>Kelechi V. Olawoyin </span> Head, Human Resources, Admin &#38; Communications, Transport Services Limited, as she shares on <span className='font-semibold'>How to build a compelling talent attraction strategy for your business</span>.
                                        {/* <br /> <br />
                                        You don’t want to miss this one! */}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='py-8 lg:w-2/5 rounded' >
                            <div className="font-work-sans px-8  py-6" style={{ "minHeight": "500px", "border": "1px solid #9CA3AF" }}>
                                <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                                <WebinarFourForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WebinarFour;

