import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
    return (
        <div id="terms" className="pt-12 pb-20 container">
            <div>

                <div className="my-6 font-semibold text-2xl md:text-3xl text-center">TERMS & CONDITIONS</div>
                <div className="my-6 pb-10 font-medium text-xl text-center text-ep-primary border-b-2 border-black">
                    {/* FOR USE OF EARNIPAY’S SAVINGS FEATURE ON THE EARNIPAY APP */}
                    For use of Earnipay's savings feature on the Earnipay app
                </div>

                <div className="terms md:px-6">
                    
                    <ol className="parent-ol">
                        <div className="terms-title">
                            INTRODUCTION
                        </div>
                        
                        <li className="parent-li">
                            Earnipay Limited (“Earnipay”) owns the financial technology solution/application that provides employees with flexible and on demand access to accrued/earned salaries through a pre-arrangement with their employers.
                        </li>

                        <li className="parent-li">
                            The following Terms and Conditions apply to any person that registers for and/or opens an Earnipay Account through earnipay.com or any associated mobile applications, website, or APIs (together, the “Earnipay Site”) and more directly, signs up for the savings feature on Earnipay’s application.
                        </li>

                        <li className="parent-li">
                            By accessing the Earnipay application, Users agree to these Terms and Conditions. Earnipay reserves the right to alter or modify portions of these terms from time to time. Kindly check these terms periodically for updates. For the purpose of these Terms, any reference to “we” “us” “our” “Earnipay” and/or any similar term shall be construed as reference to Earnipay Limited.
                        </li>

                        <div className="terms-title">
                            INTERPRETATION
                        </div>

                        <li className="parent-li">
                            Any reference to the following words in this Terms and Conditions shall have the following meaning except otherwise expressly stated:
                            <table className="mt-4 table-style-2">
                                <thead>
                                    <tr>
                                        <th>
                                            S/N
                                        </th>
                                        <th>
                                            Terms
                                        </th>
                                        <th>
                                            Meaning
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            1.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Breaking Fee
                                            </span>
                                        </td>
                                        <td>
                                            The penal charge that is a 2.5% of the withdrawal amount. It is charged when a User withdraws from their saved amount before the maturity date of the set goal.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            2.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Goal Amount
                                            </span>
                                        </td>
                                        <td>
                                            The amount that the User plans to save by the Maturity Date.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            3.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Interest Rate
                                            </span>
                                        </td>
                                        <td>
                                            The percentage returns on the User’s goal amount. This is calculated per annum. Note that: When this rate is changed, it applies to all active savings goals from the update date, ensuring all savings always earn the most current rate available.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            4.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Maturity Date
                                            </span>
                                        </td>
                                        <td>
                                            The agreed date set for the User’s Savings Goal when the User can withdraw the savings without penalty and the date the interest on the User’s Savings Amount will be fully applied to the User’s savings.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            5.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Savings Amount
                                            </span>
                                        </td>
                                        <td>
                                            The sum deducted from the User’s card or wallet, adjusted according to their selected frequency, to achieve their Goal amount. This value is computed by the savings system, taking into account the user’s goal amount and savings duration (from the start date to the end date), and is transparently displayed to the user.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            6.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Savings Start Date
                                            </span>
                                        </td>
                                        <td>
                                            The date the User inputs for the daily savings to commence;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            7.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Savings End Date
                                            </span>
                                        </td>
                                        <td>
                                            The date on which the User’s savings goal period concludes, marking the end of the planned duration for saving the desired amount.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            8.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                User(s)
                                            </span>
                                        </td>
                                        <td>
                                            Any third party who accesses the Earnipay Site/application and is neither employed by Earnipay and acting in course of an employment with it or engaged as a consultant nor otherwise providing services to Earnipay and accessing the platform(s) in connection with the provision of such services. Users are eligible adults who include employers and employees.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            9.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Earnipay Site
                                            </span>
                                        </td>
                                        <td>
                                            Any of the following; earnipay.com or any associated mobile applications, website, or APIs.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            10.
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                Services
                                            </span>
                                        </td>
                                        <td>
                                            Savings solutions on Earnipay application that gives Users the opportunity to save and reaching a savings goal
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>

                        <div className="terms-title">
                            CONTRACTUAL RELATIONSHIP
                        </div>

                        <li className="parent-li">
                            Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Earnipay. If you do not agree to these Terms, you may not access or use the Services. These Terms expressly supersede prior agreements or arrangements with you. Earnipay may immediately terminate these Terms or any Services with respect to you, or generally cease o ering or deny access to the Services or any portion thereof, at any time for any reason.
                        </li>

                        <li className="parent-li">
                            Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable services. Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services.
                        </li>

                        <li className="parent-li">
                            Earnipay may amend the Terms related to the Services from time to time. Amendments will be e ective upon Earnipay’s posting of updated Terms at this location or the amended policies or supplemental terms on the applicable Service. Your continued access or use of the Services after such posting constitutes your consent to be bound by the Terms as amended.
                        </li>

                        <li className="parent-li">
                            Our collection and use of personal information in connection with the Services is as provided in Earnipay’s Privacy Policy located at <Link to="/privacy-policy" className="text-ep-primary-400 hover:underline">https://earnipay.com/privacy-policy</Link>.
                            Earnipay may provide to a claims’ processor or an insurer any necessary information (including your contact information) if there is a complaint, dispute or conflict involving you and a Third-Party Provider and such information or data is necessary to resolve the complaint, dispute or conflict.
                        </li>

                        <div className="terms-title">
                            SERVICES
                        </div>

                        <li className="parent-li">
                            The Services constitute access to Earnipay’s savings feature on Earnipay’s site/application to give Users the opportunity and benefit to save and reach a savings goal.
                        </li>

                        <div className="terms-title">
                            SAVINGS
                        </div>

                        <li className="parent-li">
                            Earnipay will help the User set aside funds from the User’s wallet or debit card to
                            meet the goal amount stipulated by the User.
                        </li>

                        <li className="parent-li">
                            The User will in turn state the name of the goal amount and the amount that will be
                            saved. A User can only edit:&nbsp;
                            <span className="italic">
                                i) Goal Title, ii) Goal Amount (if the goal is not yet active) and iii) Savings Amount (if the goal is not yet active).
                            </span>
                        </li>

                        <li className="parent-li">
                            The User’s savings will commence on the Savings Start Date.
                        </li>

                        <li className="parent-li">
                            The User will determine his/her savings goal and frequency. The User can select any
                            of the following savings’ frequency:
                            <div className="mt-4">
                                <ol className="lower-alpha pl-4">
                                    <li>
                                        <span className="font-semibold">Daily</span> - The User’s funds will be deducted daily to meet the User’s Goal Amount;
                                    </li>
                                    <li>
                                        <span className="font-semibold">Weekly</span> - The User funds will be deducted once a week, on a selected day to meet the User’s Goal Amount;
                                    </li>
                                    <li>
                                        <span className="font-semibold">Monthly</span> - The User’s funds will be deducted once a month, on a selected date in the month to meet User’s Goal Amount.
                                    </li>
                                </ol>
                            </div>
                        </li>

                        <li className="parent-li">
                            The Duration for the savings goal will be minimum of 1 month
                        </li>

                        <li className="parent-li">
                            Interest at the rate of 15% will accrue per annum on the User’s Savings Amount;
                        </li>

                        <li className="parent-li">
                            Earnipay can update the interest rate and the breaking fee on the User’s savings before the Savings End Date based on money market conditions. The updated rate will be applied to the User’s savings from the date the rate was updated.
                        </li>

                        <li className="parent-li">
                            Earnipay will notify the User before interest rate is updated.
                        </li>

                        <li className="parent-li">
                            Users can withdraw from their saved amount at any time to their wallet account at a breaking fee cost.
                        </li>

                        <li className="parent-li">
                            The User can cancel his active savings goal at the Breaking Fee rate.
                        </li>

                        <li className="parent-li">
                            No further withdrawals would be made from the User’s card/wallet, and Savings Amount less of his cancellation/Breaking Fee.
                        </li>

                        <li className="parent-li">
                            The User can pause his withdrawals over a period of time or indefinitely- this means no further daily withdrawals would be carried out to his saved amount.
                        </li>

                        <li className="parent-li">
                            The saved amount remains and gains daily interest and can be withdrawn through the withdrawal process.
                        </li>

                        <li className="parent-li">
                            The User can resume his savings that were previously paused and after the resumption, the Maturity Date will be adjusted to meet the User’s savings goal.
                        </li>

                        <div className="terms-title">
                            BREAKING FEE
                        </div>

                        <li className="parent-li">
                            The User will pay Earnipay the Breaking Fee for withdrawing the Savings Amount before the Maturity Date.
                        </li>

                        <div className="terms-title">
                            ACCOUNT SECURITY
                        </div>

                        <li className="parent-li">
                            Earnipay takes security very seriously. However, the User is solely responsible for:
                            <div className="mt-4">
                                <ul className="pl-4">
                                    <li>
                                        maintaining adequate security and control over an Earnipay Account sign-in details, including but not limited to any passwords, API keys, or any other codes associated with an Earnipay Account;
                                    </li>
                                    <li>
                                        enabling any additional security features available to the User, including the activation of two factor authentication;
                                    </li>
                                    <li>
                                        keeping the User’s contact details up-to-date so that the User can receive any notices or alerts that Earnipay may send in relation to security;
                                    </li>
                                    <li>
                                        maintaining security and control over the email mailbox, phone number and two-factor authentication applications or devices associated with the User’s Earnipay Account.
                                    </li>
                                    <li>
                                        Failure to take the above measures, and any other security measures available to the User, may result in unauthorised access to the User’s Earnipay Account. Earnipay shall have no liability to the User in this regard for or in connection with any unauthorized access to the User’s Earnipay Account, where such unauthorised access was due to no fault of Earnipay, and/or any failure by the User to act upon any notice or alert issued by Earnipay to the User.
                                    </li>
                                    <li>
                                        The security of the User’s Earnipay Account may be compromised, or interruption caused to it, by phishing, spoofing or other attack, computer viruses, spyware, scareware, Trojan horses, worms or other malware that may a ect the User’s computer or other equipment. Earnipay strongly recommends that Users regularly use reputable virus screening and prevention software and remain alert to the fact that SMS, email services and search engines are vulnerable to spoofing and phishing attacks.
                                    </li>
                                    <li>
                                        Care should be taken in reviewing messages purporting to originate from Earnipay and, should a User have any uncertainty regarding the authenticity of any communication, the User should only log on to a User Earnipay Account through the Earnipay website specifically, earnipay.com and not any other domain name or website purporting to be, or to be related to, Earnipay to review any transactions or required actions.
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className="parent-li">
                            To the maximum extent permitted by applicable law, the User accepts and agrees to have full responsibility for all activity that occurs in or through the User’s Earnipay Account and accept all risks of any unauthorized or authorized access to the User’s Earnipay Account.
                        </li>
                        
                        <div className="terms-title">
                            SERVICE AVAILABILITY
                        </div>

                        <li className="parent-li">
                            While Earnipay shall do everything reasonable to provide continuous operations, Earnipay does not provide any warranty in relation to the availability of the Earnipay Site or the User’s Earnipay Account. Without limiting the generality of the foregoing, Earnipay does not guarantee continuous access to the Earnipay Site or the User’s Earnipay Account and makes no representation that the Earnipay Site, Earnipay API, the User’s Earnipay Account and/or any products or services o ered therein will be available without interruption; or that there will be no delays, failures, errors, omissions or loss of transmitted information.
                        </li>

                        <div className="terms-title">
                            INTELLECTUAL PROPERTY
                        </div>

                        <li className="parent-li">
                            All intellectual property rights regarding the Earnipay site, documentations or information and materials on the Earnipay Site developed by or on behalf of Earnipay for the purpose of rendering the services under the Terms of Use, as well as data and statistics about the service rendered pursuant to these Terms of Use, belong to Earnipay. It shall not be copied, modified, adapted, reverse-engineered, decompiled or otherwise discover the source code of any single or combined component of the Earnipay Site or any other software used by Earnipay, save with the prior written consent or approval from Earnipay.
                        </li>

                        <div className="terms-title">
                            DISCLAIMER OF WARRANTIES
                        </div>

                        <li className="parent-li">
                            The Earnipay Site, the User Earnipay Account and any related products or services are o ered on a strictly “as-is” and “where-available” basis and Earnipay expressly disclaims, all warranties of any kind, whether express or implied. Without limiting the generality of the foregoing, the Earnipay Site, the User Earnipay Account, and any related products or services are o ered without any warranty as to merchantability or fitness for any particular purpose.
                        </li>

                        <div className="terms-title">
                            LIMITATION OF LIABILITY
                        </div>

                        <li className="parent-li">
                            In no event shall Earnipay, its operating entities or any other a liates (including their respective directors, members, employees or agents) be liable to the User for any direct, indirect, special, consequential, exemplary or punitive damages or any other damages of any kind, including but not limited to loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, whether in contract, tort or otherwise, arising out of or in any way connected with the use of, inability to use, or unavailability of the Earnipay Site and/or the User Earnipay Account, including without limitation any damages caused by or resulting from any reliance upon any information received from Earnipay, or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft, destruction or unauthorized access to Earnipay’s records, programs or services.
                        </li>

                        <div className="terms-title">
                            MISCELLANEOUS
                        </div>

                        <li className="parent-li">
                            Earnipay may assign its right and obligations to its subsidiary, agent or acquirers of its equity. A User may not assign or transfer any of the rights or obligations under these Terms without Earnipay’s prior written approval. In the event that Earnipay is acquired by or merged with a third party, it reserves the right to transfer or assign the Users’ information.
                        </li>

                        <li className="parent-li">
                            Earnipay may amend or update this Terms of Use from time to time and notify Users on any of the Earnipay Sites which shall at all time serve as due notice of such amendment or update. Continuous use of the Earnipay Site after an amendment or update, constitutes agreement with same by the User.
                        </li>

                        <li className="parent-li">
                            Earnipay shall not be liable for any delays, failure in performance or interruption of service which result directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any delay or failure due to any act of God, act of civil or military authorities, act of terrorists, civil disturbance, lockdowns, pandemics, epidemics, war, strike or other labour dispute, fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software, other catastrophe or any other occurrence which is beyond our reasonable control.
                        </li>

                        <li className="parent-li">
                            The User indemnifies Earnipay, its operating entities or any other a liates (including their respective directors, members, employees and/or agents) against any action, liability, cost, claim, loss, damage, proceeding or expense su ered or incurred directly or indirectly arising from the use of or conduct in relation to the Earnipay Site and/or the User Earnipay Account, or from the User’s violation of these Terms.
                        </li>

                        <div className="terms-title">
                            GOVERNING LAW AND DISPUTE RESOLUTION
                        </div>

                        <li className="parent-li">
                            This Terms and Conditions shall be governed by Laws of the Federal Republic of Nigeria. Your use of Earnipay’s services may also be subject to other local, state, national or international laws.
                        </li>

                    </ol>
                </div>

                <div className="mt-14 text-gray-400 text-sm">
                    Last Updated: <span className="font-semibold">20th October, 2023.</span>
                </div>
            </div>
        </div>
    )
}

export default Terms;