import React from 'react';
import marketing from "assets/images/features/campaign-one.jpg";
import HubspotContactForm from './MarketingForm2';

const  MarketingForm = () =>  {
    return (

      <div className=" bg-white overflow-x-hidden">
       <div>
        <div className="container marketing-container">
            <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
              <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                <div className="mb-4 pt-4">
                  <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>GUIDE</h5>
                  <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">The impact of employee money worries on your bottom line: a problem-solving guide.</div>
                </div>
                <div className='mx-auto caption rounded'> 
                  <div className='pb-3 lg:pr-14 rounded '>
                    <img src={marketing} alt="mareting " className='w-full h-full object-contain rounded' />
                  </div>
                  <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                    <div className='py-3'>Money worries are the top source of employee stress, but did you know it could directly affect your business's bottom line?
                    Our guide reveals the monetary impact of employee financial stress on team productivity, engagement and ability to be profitable.
                    <br /> <br />
                    Read the detailed guide to learn how to boost performance by improving financial wellbeing for your employees. </div>

                  </div>
                 
                </div>          
              </div>
              <div className='py-8 lg:w-2/5 rounded' >
                <div className="font-work-sans px-8  py-6 bg-ep-yellow" style={{"minHeight":"500px"}}>
                  <h2 className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Download Your Guide Now</h2>
                  <HubspotContactForm
                      region='eu1'
                      portalId= '25087743'
                      formId='cb7311d5-20fd-4d98-a53b-dbd063b448f7'
                    />
                </div>
              </div>
            </div>
        </div>
       </div>
      </div>
    )
}

export default MarketingForm

