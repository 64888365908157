// import React, { useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet';

const CareersPage = () => {


 const title = 'Careers | Earnipay'

     // useEffect(() => {
     //               (function (e, t, a) {
     //      var s = e.head || e.getElementsByTagName("head")[0],
     //          i = e.createElement("script");
     //      i.type = "text/javascript", i.id = "peoplehum-hire-jobs-script", i.defer = !0, i.async = !1, i.src = t +
     //          "/static/js/external-job-list.js?base_url=" + t + "&config=" + JSON.stringify(a), s.appendChild(i)
     //  })(document, 'https://hire.peoplehum.com', {
     //          env: 'prod',
     //          short_name: '63712f79-5d27-4afb-a410-0e3c753d3341',
     //          container_id: 'peopelhum-jobs-custom-container',
     //          locale: 'en-US',
     //          version: 2
     //  })
     // }, [])

  return (
   <>
     <Helmet>
      <title>{title}</title>
      <meta name="description" content='Earnipay' />
      <meta itemprop="description" content='Earnipay' />
      

      <meta property="og:url" content="https://earnipay.com/" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content='Earnipay' />

      <meta property="og:image" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
      <meta property="og:image:url" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
      <meta property="og:image:secure_url" content="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/Earnipay_website_png_1704309003186.png" />
     </Helmet>
       <section style={{
        padding: "30px 50px",
       }} id="peopelhum-jobs-container">
    </section>  
 </>
 )
}

export default CareersPage