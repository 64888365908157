import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import TypedReact from 'utils/typed.jsx';
import { ReactComponent as HomeWave } from "assets/images/icons/home-wave.svg";
import { ReactComponent as ArrowCircleLeft } from "assets/images/icons/arrow-circle-left.svg";
import { ReactComponent as ArrowCircleRight } from "assets/images/icons/arrow-circle-right.svg";
import ladyLookingAtPhone from "assets/images/main-landing/lady-looking-at-phone.png";
import venia from "assets/images/main-landing/venia.png";
import tm from "assets/images/main-landing/TM.png";
import ashLuxury from "assets/images/main-landing/ash-luxury.png";
import checkedCircle from "assets/images/checked-circle.svg";
import repeatGrid4 from "assets/images/icons/repeat-grid-4.svg";
import employeeProd from "assets/images/features/employee-prod.svg";
import best from "assets/images/features/best.svg";
import resilence from "assets/images/features/resilence.svg";
import signUp from "assets/images/how-it-works/sign-up.svg";
import withdraw from "assets/images/how-it-works/withdraw.svg";
import deduct from "assets/images/how-it-works/deduct.svg";
import balance from "assets/images/how-it-works/balance.svg";
import dashboardEmployer from "assets/images/phones/dashboardemployer.svg";
import phoneEmployee from "assets/images/phones/phoneemployee.svg";
import phoneDownload from "assets/images/phones/phone-download.svg";
import appStore from "assets/images/cta/app_store_x1.png";
import playStore from "assets/images/cta/play_store.png";
import TakeoutMedia from "assets/images/TakeoutMedia.png";
import testimonials2 from "assets/images/testimonials/t2.png";
import testimonials3 from "assets/images/testimonials/t3.jpeg";
import 'react-slideshow-image/dist/styles.css';


const slideshowProperties = {
    duration: 3000,
    transitionDuration: 300,
    pauseOnHover: true,
    indicators: false,
    autoplay: true,
    infinite: true,
    canSwipe: true,
    prevArrow: <ArrowCircleRight className="testimony-slide-next testimony-slide-click text-gray-400 hover:text-ep-blue customizeSVG" />,
    nextArrow: <ArrowCircleLeft className="testimony-slide-prev testimony-slide-click text-gray-400 hover:text-ep-blue customizeSVG" />,
    slidesToShow: 2,
}

const slideshowPropertiesMobile = {
    ...slideshowProperties,
    slidesToShow: 1,
}



class Home extends Component {
    state = {};


    render() {

        return (
            <div>
                

                <div id="home" className="-mt-20 pt-20"></div>

                <div className="relative">
                    <div className="relative z-10 container pt-6 pb-14 md:pb-24">
                        <div className="flex justify-between">

                            <div className="mt-6 sm:mt-10 md:mt-4 lg:mt-8 xl:mt-12 w-full md:w-1/2 overflow-hidden">
                                <div className="md:mr-4 xl:mr-0">
                                    <div className="text-3xl sm:text-4xl md:text-3xl xl:text-4xl 2xl:text-5xl max-w-xl font-semibold font-boing text-ep-black scale-in-ver-bottom-first">
                                        Flexible and <span style={{"whiteSpace":"nowrap"}}>On-Demand</span>&nbsp;
                                        <br className="hidden lg:inline-block" />
                                        Salary Access
                                    </div>
                                    <div className="home-title-text-line-2">
                                        <div className="typed-home-text-color-400 text-gray-400 text-3xl sm:text-4xl md:text-3xl xl:text-4xl 2xl:text-5xl max-w-xl mt-2 font-semibold scale-in-ver-bottom-first">
                                            <TypedReact
                                                strings={[
                                                    'to unlock cash flow stuck in monthly pay cycles',
                                                    'so employees never run out of cash before the next payday',
                                                    'to improve employee productivity and engagement'
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2 2xl:mt-4">
                                        <HomeWave className="customizeSVG typed-home-text-color-400 text-gray-400 ml-4" />
                                    </div>
                                    <div className="w-full md:max-w-xs lg:max-w-md sm:text-lg mt-2 2xl:mt-4 font-light scale-in-ver-bottom-second">
                                        Earnipay enables businesses to offer employees access to their earned salaries instantly at any time without making changes to their existing payment process.
                                    </div>
                                    <div className="mt-8">
                                        <Link to={{pathname: "https://business.earnipay.com/get-started"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn-ep-blue btn-lg scale-in-ver-bottom-third">
                                            Get Started For Free
                                        </Link>
                                    </div>
                                    <div className="mt-8 scale-in-ver-bottom-fourth">
                                        <span className="font-bold text-sm">
                                            Offered by leading businesses
                                        </span>
                                        <div className="flex flex-wrap items-center space-x-2 space-y-2">
                                            <img src={venia} className="h-14 sm:h-16 mt-3" alt="client" />
                                            <img src={tm} className="h-14 sm:h-16" alt="client" />
                                            <img src={ashLuxury} className="h-14 sm:h-16" alt="client" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hidden md:inline-block xl:flex justify-end w-1/2">
                                <div className="relative">
                                    <div className="bg-gray-100 border-gray-100 typed-home-bg-color-100 typed-home-border-color-100 md:mt-16 lg:mt-0 xl:w-max rounded-2xl overflow-hidden" style={{"borderWidth":"10px"}} >
                                        <div className="bg-gray-300 border-gray-300 typed-home-bg-color-300 typed-home-border-color-300 xl:w-max rounded-xl border-4 overflow-hidden">
                                            <img src={ladyLookingAtPhone} className="xl:max-w-full home-img-girl" alt="lady using earnipay" />
                                        </div>
                                    </div>
                                    <img src={repeatGrid4} className="absolute z-0 bottom-24 -left-28 h-44" alt="decoration" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg-img-under bg-confetti-under"></div>
                </div>


                <div className="py-20 relative" style={{"backgroundColor":"#EAF6FC"}}>
                    <div id="features" className="relative z-10 container">
                        <div className="">

                            <div className="mb-12">
                                <div className="text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    A better way to pay that benefits everyone.
                                </div>
                                <div className="font-light w-11/12 mt-4 sm:text-lg lg:text-xl">
                                    Over 50% of African workers earn less than $700 a month. 1 in 3 employees run out of cash before payday leading to financial stress and distractions in the workplace. Employers stand to achieve significant ROI and improve employee quality of life by offering flexible access to earned salaries.
                                </div>
                            </div>

                            <div className="overflow-hidden grid sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-8">

                                    <div className="h-full flex sm:block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div className="flex-shrink-0">
                                            <img src={employeeProd} alt="Improve Employee Productivity" className="h-24 w-24" />
                                        </div>
                                        <div className="sm:mt-6 ml-6 sm:ml-0">
                                            <div className="text-lg sm:text-xl lg:text-3xl font-semibold font-boing">
                                                Improve Employee Productivity
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Reduce distractions caused due to financial short falls and lack of disposable income
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full flex sm:block p-8 xl:p-14 rounded-xl shadow-sm text-white" style={{"backgroundColor":"#416ff4"}}>
                                        <div className="flex-shrink-0">
                                            <img src={best} alt="Attract and Retain Best Talent" className="h-24 w-24" />
                                        </div>
                                        <div className="sm:mt-6 ml-6 sm:ml-0">
                                            <div className="text-lg sm:text-xl lg:text-3xl font-semibold font-boing">
                                                Attract and Retain Best Talent
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Incentivise current and future employees with the #1 employee benefit they need
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full flex sm:block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div className="flex-shrink-0">
                                            <img src={resilence} alt="Build Financial Resilience" className="h-24 w-24" />
                                        </div>
                                        <div className="sm:mt-6 ml-6 sm:ml-0">
                                            <div className="text-lg sm:text-xl lg:text-3xl font-semibold font-boing">
                                                Build Financial Resilience
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Empower employees to withstand unexpected expenses and avoid unnecessary borrowing.
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-under"></div>
                </div>


                <div id="how-it-works" className="py-20 relative" style={{"backgroundColor":"#FFE7AA"}}>
                    <div className="relative z-10 container">
                        <div className="">

                            <div className="mb-12 text-center">
                                <div className="text-4xl md:text-5xl font-semibold font-boing text-ep-black">
                                    How does Earnipay Work?
                                </div>
                                <div className="font-light mt-4 sm:text-lg lg:text-xl">
                                    Employees can withdraw up to 50% of their accrued salary whenever they need it the most. 
                                </div>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 justify-between">
                                <div className="text-center">
                                    <img src={signUp} alt="sign up" className="mx-auto" />
                                    <div className="text-3xl font-semibold mt-8 font-boing">
                                        Sign Up
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employer signs up for Earnipay and adds Employees
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={withdraw} alt="sign up" className="mx-auto" />
                                    <div className="text-3xl font-semibold mt-8 font-boing">
                                        Withdraw
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employee withdraws accrued salary at any time of the month. Earnipay funds the withdrawal
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={deduct} alt="sign up" className="mx-auto" />
                                    <div className="text-3xl font-semibold mt-8 font-boing">
                                        Deduct
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employer deducts withdrawn amount from Employee salary and returns to Earnipay. 
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={balance} alt="sign up" className="mx-auto" />
                                    <div className="text-3xl font-semibold mt-8 font-boing">
                                        Balance
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employee receives balance of withdrawn amount from salary at the end of the month. 
                                    </div>
                                </div>
                            </div>
                            
                            <div className="text-ep-blue text-lg text-center mt-16 font-bold">
                                Payments reconciled monthly.
                            </div>

                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-under"></div>
                </div>

                
                <div className="py-20" id="benefits">

                    <div className="container">
                        <div className="md:flex">
                            <div className="w-full md:w-6/12">
                                <div className="text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    Employer benefits
                                </div>
                                <div className="mt-12 space-y-8 md:mr-10">
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Free to set up and no maintenance fees
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Quick deployment with seamless integration to existing payment systems.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Zero liability and no pressure on business cash flow to fund salary advances
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Administrative dashboard for oversight, control and reporting.
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <Link to={{pathname: "https://business.earnipay.com/get-started"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault();}} className="btn btn-ep-blue btn-lg">
                                        Get Started For Free
                                    </Link>
                                </div>
                                <div className="mt-6">
                                    <span className="text-gray-500">
                                        For More Information, Visit the
                                    </span>
                                    <Link to="/faqs" className="text-ep-blue pl-1 hover:underline">FAQs Section</Link>
                                </div>
                            </div>
                            <div className="md:w-6/12 hidden md:block">
                                <img src={dashboardEmployer} alt="phone" className="mt-12 relative xl:-right-20" />
                            </div>
                        </div>
                    </div>

                    <div id="employee" className="pt-24 container">
                        <div className="md:flex">
                            <div className="md:w-6/12 md:block hidden">
                                <img src={phoneEmployee} alt="phone" className="" />
                            </div>
                            <div className="w-full md:w-6/12 md:mt-10">
                                <div className="text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    Employee benefits
                                </div>
                                <div className="mt-12 space-y-8 md:mr-10">
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Anyday is payday with Earnipay
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Financial safety net to cover expenses and urgent payments.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Avoid unnecessary borrowing and being a target for predatory payday lenders.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Access accrued salary on the go via mobile app or USSD.
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <Link to={{pathname:"https://form.typeform.com/to/Djujg850"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault();}} className="btn btn-ep-blue btn-lg">
                                        Refer your Employer
                                    </Link>
                                </div>
                                <div className="mt-6">
                                    <span className="text-gray-500">
                                        For More Information, Visit the
                                    </span>
                                    <Link to="/faqs" className="text-ep-blue pl-1 hover:underline">FAQs Section</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                
                <div className="relative" style={{"backgroundColor":"#05083F"}}>
                    <div className="relative z-10 container">
                        <div className="pt-20 pb-20 lg:pb-0 lg:flex lg:justify-between">
                            <div className="lg:mt-10">
                                <div className="text-4xl lg:text-5xl text-center lg:text-left mr-0 lg:mr-40 font-semibold text-white" style={{"lineHeight":"1.2"}}>
                                    Download the&nbsp;
                                    <br className="hidden lg:block" />
                                    Earnipay app
                                </div>
                                <div className="flex mt-6 lg:mb-10 justify-center lg:justify-start">
                                    <Link to="/#" className="inline-block mt-2 mr-2 md:mr-4 pl-0" target="_blank" rel="noreferrer">
                                        <img src={appStore} className="cursor-pointer h-12 sm:h-16" alt="apple appstore" />
                                    </Link>
                                    <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="inline-block mt-2 mr-2 md:mr-4 pl-0" target="_blank" rel="noreferrer">
                                        <img src={playStore} className="cursor-pointer h-12 sm:h-16" alt="google playstore" />
                                    </Link>
                                </div>
                            </div>
                            <img src={phoneDownload} style={{"height":"22rem"}} className="hidden lg:block" alt="phoneDownload" />
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-2-under"></div>
                </div>


                <div className="mt-16 md:mt-20" id="testimonials">
                    <div className="container">
                        <div className="">

                            <div className="flex justify-between">
                                <div className="text-left text-4xl xl:text-5xl w-72 font-semibold font-boing">
                                    What people <br />say about us
                                </div>

                                <div className="hidden h-24 space-x-4 pt-6 flex justify-between">
                                    <ArrowCircleRight onClick={this.testimonySliderPrev} className="customizeSVG text-ep-blue cursor-pointer" />
                                    <ArrowCircleLeft onClick={this.testimonySliderNext} className="customizeSVG text-gray-300 cursor-pointer" />
                                </div>
                            </div>
                            
                            <div className="py-10  relative">
                                <div className="relative z-10">

                                    <div className="hidden md:block">
                                        <Slide {...slideshowProperties} easing="ease" as="div" className="">

                                            <div className="h-full mt-10 mx-4 pt-14 px-14 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials2} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-lg lg:text-xl font-semibold">
                                                            Solomon Dawudu
                                                        </div>
                                                        <div className="opacity-40 text-black text-base">
                                                            Co Founder of Takeout Media
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-6 leading-6">
                                                    Running a business can be demanding, having motivated employees is paramount to our success and with earnipay’s flexible salary payment solutions, productivity isn’t a demand anymore, it’s a lifestyle.
                                                </div>
                                                <img src={TakeoutMedia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                            <div className="h-full mt-10 mx-4 pt-14 px-14 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials3} alt="Adebowale Banjo" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-lg lg:text-xl font-semibold">
                                                            Adebowale Banjo
                                                        </div>
                                                        <div className="opacity-40 text-black text-base">
                                                            CEO of Venia Group
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-6 leading-6">
                                                    Earnipay's flexible salary has been such a positive impact in our organisation, our employees are able to access their earned salaries at will without any added pressure on our payroll and accounting systems.
                                                </div>
                                                <img src={venia} alt="venia" className="h-16" />
                                            </div>

                                        </Slide>
                                    </div>

                                    <div className="block md:hidden">
                                        <Slide {...slideshowPropertiesMobile} easing="ease">

                                            <div className="m-4 p-6 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials2} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-base font-semibold">
                                                            Solomon Dawudu
                                                        </div>
                                                        <div className="opacity-40 text-black text-sm">
                                                            Co Founder of Takeout Media
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6 pb-2 leading-6">
                                                    Running a business can be demanding, having motivated employees is paramount to our success and with earnipay’s flexible salary payment solutions, productivity isn’t a demand anymore, it’s a lifestyle.
                                                </div>
                                                <img src={TakeoutMedia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                            <div className="m-4 p-6 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials3} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-base font-semibold">
                                                            Adebowale Banjo
                                                        </div>
                                                        <div className="opacity-40 text-black text-sm">
                                                            CEO of Venia Group
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6 pb-2 leading-6">
                                                    Earnipay's flexible salary has been such a positive impact in our organisation, our employees are able to access their earned salaries at will without any added pressure on our payroll and accounting systems.
                                                </div>
                                                <img src={venia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                        </Slide>
                                    </div>
                                    
                                </div>
                                <div className="bg-img-under bg-map-under"></div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="relative" style={{"backgroundColor":"#05083F"}}>
                    <div className="relative z-10 container">
                        <div className="pb-20 pt-10 md:px-12 px-6 flex items-center text-center flex-col">
                            <div className="text-2xl md:text-4xl xl:text-5xl text-center font-semibold text-white pt-16 pb-10 font-boing">
                                Join the Earnipay Movement
                            </div>
                            <Link to={{pathname: "https://business.earnipay.com/get-started"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn-ep-blue btn-lg">
                                Get Started For Free
                            </Link>
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-2-under"></div>
                </div>


            </div>
        );
    }
}

export default Home;
