import React, { Component } from "react";
import { Link } from "react-router-dom";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { Slide } from 'react-slideshow-image';
// import Slider from "react-slick";
import queryString from 'query-string';
import logger from "utils/logger";
import TypedReact from 'utils/typed.jsx';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as PlayVideo } from "assets/images/icons/play-video.svg";
import { ReactComponent as HomeWave } from "assets/images/icons/home-wave.svg";
import { ReactComponent as ArrowCircleLeft } from "assets/images/icons/arrow-circle-left.svg";
import { ReactComponent as ArrowCircleRight } from "assets/images/icons/arrow-circle-right.svg";
import introVideo from "assets/videos/intro-video.mp4";
import ladyLookingAtPhone from "assets/images/main-landing/lady-looking-at-phone.png";
import venia from "assets/images/main-landing/venia.png";
import tm from "assets/images/main-landing/TM.png";
import ashLuxury from "assets/images/main-landing/ash-luxury.png";
import checkedCircle from "assets/images/checked-circle.svg";
import repeatGrid4 from "assets/images/icons/repeat-grid-4.svg";
import employeeProd from "assets/images/features/employee-prod.svg";
import best from "assets/images/features/best.svg";
import resilence from "assets/images/features/resilence.svg";
import signUp from "assets/images/how-it-works/sign-up.svg";
import withdraw from "assets/images/how-it-works/withdraw.svg";
import deduct from "assets/images/how-it-works/deduct.svg";
import balance from "assets/images/how-it-works/balance.svg";
import dashboardEmployer from "assets/images/phones/dashboardemployer.svg";
import phoneEmployee from "assets/images/phones/phoneemployee.svg";
import phoneDownload from "assets/images/phones/phone-download.svg";
import appStore from "assets/images/cta/app_store_x1.png";
import playStore from "assets/images/cta/play_store.png";
import TakeoutMedia from "assets/images/TakeoutMedia.png";
import testimonials2 from "assets/images/testimonials/t2.png";
import testimonials3 from "assets/images/testimonials/t3.jpeg";
import brandBluechip from "assets/images/trustedBrands/bluechip.png";
import brandBrandco from "assets/images/trustedBrands/brandco.png";
import brandBrooksBlake from "assets/images/trustedBrands/brooks-blake.png";
import brandCuracel from "assets/images/trustedBrands/curacel.png";
import brandEden from "assets/images/trustedBrands/eden.png";
import brandSend from "assets/images/trustedBrands/send.png";
import brandThrive from "assets/images/trustedBrands/thrive.png";
import 'react-slideshow-image/dist/styles.css';


const slideshowProperties = {
    duration: 3000,
    transitionDuration: 300,
    pauseOnHover: true,
    indicators: false,
    autoplay: true,
    infinite: true,
    canSwipe: true,
    prevArrow: <ArrowCircleRight className="testimony-slide-next testimony-slide-click text-gray-400 hover:text-ep-blue customizeSVG" />,
    nextArrow: <ArrowCircleLeft className="testimony-slide-prev testimony-slide-click text-gray-400 hover:text-ep-blue customizeSVG" />,
    slidesToShow: 2,
}

const slideshowPropertiesMobile = {
    ...slideshowProperties,
    slidesToShow: 1,
}

class Home extends Component {
    state = {
        showModalAssignToGroup: false,
    };


    playDemoVideo = () => {
        const { showModalAssignToGroup } = this.state;
        this.setState({showModalAssignToGroup: !showModalAssignToGroup}, () => {
            const introVideo = document.querySelector(".intro-video");
            // introVideo.setAttribute("autoPlay","");
            introVideo.currentTime = 0;
            introVideo.play();
        });

    }


    closeModalAssignToGroup = (action) => {
        const introVideo = document.querySelector(".intro-video");
        introVideo.pause();
        this.setState({showModalAssignToGroup: action});
    }


    componentDidMount() {
        logger("website-homepage");

        const qs = queryString.parse(this.props.location.search);
        if ((qs.referral !== undefined) && (qs.referral !== null)){
            window.location.href = process.env.REACT_APP_EMPLOYEE_APP_URL + "/get-started/referral/" + qs.referral;
        }
    }


    render() {
        customizeSVG();
        const { showModalAssignToGroup } = this.state;
        

        return (
            <div>
                

                <Dialog open={showModalAssignToGroup} initialFocus={this.completeButtonRef} onClose={() => this.closeModalAssignToGroup(false)} className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-white opacity-95" />

                        <div className="w-full max-w-lg mx-auto relative bg-black rounded overflow-hidden">
                            <div className="h-12 px-4 bg-ep-blue flex justify-between overflow-hidden">
                                <div className="invisible my-auto text-white">
                                    Intro Video
                                </div>
                                <div onClick={() => this.closeModalAssignToGroup(false)} ref={this.completeButtonRef} className="w-7 h-7 flex my-auto border group hover:bg-red-400 hover:border-red-400 border-white rounded-lg cursor-pointer">
                                    <FontAwesomeIcon icon="times" className="text-white m-auto" />
                                </div>
                            </div>

                            <div>
                                <video src={introVideo} type="video/mp4" width="100%" controls className="intro-video" />
                            </div>

                        </div>

                    </div>
                </Dialog>



                <div id="home" className="-mt-20 pt-20"></div>

                <div className="relative">
                    <div className="relative z-10 container pt-6 pb-14 md:pb-24">
                        <div className="flex justify-between">

                            <div className="mt-6 sm:mt-10 md:mt-4 lg:mt-8 xl:mt-12 w-full md:w-1/2">
                                <div className="md:mr-4 xl:mr-0">
                                    <div className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl max-w-xl font-semibold font-boing text-ep-black scale-in-ver-bottom-first">
                                        {/* 
                                            Flexible and <span style={{"whiteSpace":"nowrap"}}>On-Demand</span>&nbsp;
                                            <br className="hidden lg:inline-block" />
                                            Salary Access
                                        */}
                                        Make anyday payday for your employees
                                        {/* 
                                            Make everyday&nbsp;
                                            <br className="hidden xl:inline-block" />
                                            payday for your&nbsp;
                                            <br className="hidden xl:inline-block" />
                                            employees
                                        */}
                                    </div>
                                    <div className="hidden home-title-text-line-2">
                                        <div className="typed-home-text-color-400 text-gray-400 text-3xl sm:text-4xl md:text-3xl xl:text-4xl 2xl:text-5xl font-boing max-w-xl mt-2 font-semibold scale-in-ver-bottom-first">
                                            <TypedReact
                                                strings={[
                                                    'to unlock cash flow <br class="hidden bg-red-300 2xl:inline-block" /> stuck in monthly <br class="bg-blue-300 hidden 2xl:inline-block" /> pay cycles',
                                                    'so employees never run out of cash before the next payday',
                                                    'to improve employee productivity and engagement'
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <HomeWave className="customizeSVG typed-home-text-color-400 text-gray-400 ml-4" />
                                    </div>
                                    <div className="w-full md:max-w-xs lg:max-w-lg sm:text-lg mt-2 2xl:mt-4 font-light scale-in-ver-bottom-second">
                                        Empower your workforce to take control of their finances with innovative earn-as-you-work technology. They are able to access their earned salaries at any time before pay day. 
                                    </div>
                                    <div className="mt-8 sm:flex sm:space-x-2 space-y-2 sm:space-y-0">
                                        <Link to="/get-started" className="btn btn-ep-blue btn-lg scale-in-ver-bottom-third">
                                            Get Started For Free
                                        </Link>
                                        <button type="button" onClick={this.playDemoVideo} className="w-max flex items-center justify-center space-x-2 rounded px-5 py-1 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-opacity-75 overflow-hidden">
                                            <PlayVideo className="w-8 h-8" />
                                            <span className="text-ep-blue font-bold text-base">
                                                Watch Demo
                                            </span>
                                        </button>
                                    </div>
                                    <div className="hidden mt-8 scale-in-ver-bottom-fourth">
                                        <span className="font-bold text-sm">
                                            Offered by leading businesses
                                        </span>
                                        <div className="flex flex-wrap items-center space-x-2 space-y-2">
                                            <img src={venia} className="h-14 sm:h-16 mt-3" alt="client" />
                                            <img src={tm} className="h-14 sm:h-16" alt="client" />
                                            <img src={ashLuxury} className="h-14 sm:h-16" alt="client" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hidden md:inline-block lg:flex justify-end w-1/2">
                                <div className="relative">
                                    <div className="bg-gray-100 border-gray-100 typed-home-bg-color-100 typed-home-border-color-100 md:mt-16 lg:mt-0 lg:w-max rounded-2xl overflow-hidden" style={{"borderWidth":"10px"}} >
                                        <div className="bg-gray-300 border-gray-300 typed-home-bg-color-300 typed-home-border-color-300 lg:w-max rounded-xl border-4 overflow-hidden">
                                            <img src={ladyLookingAtPhone} className="xl:max-w-full home-img-girl" alt="lady using earnipay" />
                                        </div>
                                    </div>
                                    <img src={repeatGrid4} className="absolute z-0 bottom-24 -left-28 h-44" alt="decoration" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bg-img-under bg-confetti-under"></div>
                </div>


                <div className="relative md:-mt-6">
                    <div className="container">
                        <div className="text-center font-boing font-semibold uppercase text-xs text-fade" style={{"letterSpacing":"5px"}}>
                            Trusted by
                        </div>
                        <div className="flex flex-wrap justify-center items-center mt-4 mb-16">

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandBluechip} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandSend} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandEden} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandBrooksBlake} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandThrive} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandBrandco} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                            <div className="h-6 lg:h-8 flex m-4 sm:m-6">
                                <img src={brandCuracel} alt="Trusted Brands" className="h-full mx-auto object-contain" />
                            </div>

                        </div>
                    </div>
                </div>


                <div className="pt-20 sm:pt-24 pb-20 sm:pb-28 relative" style={{"backgroundColor":"#EAF6FC"}}>
                    <div id="features" className="relative z-10 container">
                        <div className="">

                            <div className="mb-16">
                                <div className="text-3xl sm:text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    A better way to pay
                                </div>
                                <div className="font-light w-11/12 mt-4 sm:text-lg lg:text-xl">
                                    Innovating how your employees access pay is good for focus, good for business.
                                </div>
                            </div>

                            <div className="overflow-hidden grid sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-8">

                                    <div className="h-full block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div>
                                            <img src={employeeProd} alt="Improve Employee Productivity" className="h-24 w-24" />
                                        </div>
                                        <div className="mt-6">
                                            <div className="text-xl lg:text-3xl font-semibold font-boing">
                                                Improve employee productivity
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Help your workforce keep focus with a financial safety net to rely on.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full block p-8 xl:p-14 rounded-xl shadow-sm text-white" style={{"backgroundColor":"#416ff4"}}>
                                        <div>
                                            <img src={best} alt="Attract and Retain Best Talent" className="h-24 w-24" />
                                        </div>
                                        <div className="mt-6">
                                            <div className="text-xl lg:text-3xl font-semibold font-boing">
                                                Keep your workforce happy
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Incentivise employees with the #1 employee benefit - access to money they need.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div>
                                            <img src={resilence} alt="Build Financial Resilience" className="h-24 w-24" />
                                        </div>
                                        <div className="mt-6">
                                            <div className="text-xl lg:text-3xl font-semibold font-boing">
                                                Optimise your operations
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                Maximise your funds; No more manual salary advances, no associated costs.
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-under"></div>
                </div>


                <div id="how-it-works" className="pt-16 sm:pt-24 pb-16 sm:pb-28 relative" style={{"backgroundColor":"#FFE7AA"}}>
                    <div className="relative z-10 container">
                        <div className="">

                            <div className="mb-12 text-center">
                                <div className="text-3xl sm:text-4xl md:text-5xl font-semibold font-boing text-ep-black">
                                    How does Earnipay Work?
                                </div>
                                <div className="font-light mt-4 sm:text-lg lg:text-xl">
                                    Employees can withdraw up to 50% of their accrued salary whenever they need it the most. 
                                </div>
                            </div>

                            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 justify-between">
                                <div className="text-center">
                                    <img src={signUp} alt="sign up" className="mx-auto" />
                                    <div className="text-2xl sm:text-3xl font-semibold mt-8 font-boing">
                                        Sign Up
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Your organisation signs up and your employees are enrolled on Earnipay.
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={withdraw} alt="sign up" className="mx-auto" />
                                    <div className="text-2xl sm:text-3xl font-semibold mt-8 font-boing">
                                        Earn
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employees receive daily earnings based on their pro-rated monthly salary.
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={deduct} alt="sign up" className="mx-auto" />
                                    <div className="text-2xl sm:text-3xl font-semibold mt-8 font-boing">
                                        Access
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Employees can draw down on daily earnings, funded by Earnipay.
                                    </div>
                                </div>
                                <div className="text-center">
                                    <img src={balance} alt="sign up" className="mx-auto" />
                                    <div className="text-2xl sm:text-3xl font-semibold mt-8 font-boing">
                                        Balance
                                    </div>
                                    <div className="font-light mt-6 text-lg">
                                        Drawn down amount is deducted from employee's monthly salary. Employee receives net balance on normal payday.
                                    </div>
                                </div>
                            </div>
                            
                            <div className="text-ep-blue text-lg text-center mt-16 font-bold">
                                Payments reconciled monthly.
                            </div>

                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-under"></div>
                </div>

                
                <div className="pb-16 sm:pb-32" id="benefits">

                    <div id="employer" className="pt-16 sm:pt-32 container">
                        <div className="md:flex">
                            <div className="w-full md:w-6/12">
                                <div className="text-3xl sm:text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    Employer’s benefits
                                </div>
                                <div className="mt-12 space-y-8 md:mr-10">
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Boost employee productivity and motivation by helping them earn as they go.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Remove pressure on business cash flow to fund salary advances.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Attract and retain top talent with the financial benefit they deserve.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Join the top employee-centric companies in the world.
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <Link to="/get-started" className="btn btn-ep-blue btn-lg">
                                        Get Started For Free
                                    </Link>
                                </div>
                                <div className="mt-6">
                                    <span className="text-gray-500">
                                        For More Information, Visit the
                                    </span>
                                    <Link to="/faqs" className="text-ep-blue pl-1 hover:underline">FAQs Section</Link>
                                </div>
                            </div>
                            <div className="md:w-6/12 hidden md:block">
                                <img src={dashboardEmployer} alt="phone" className="mt-12 relative xl:-right-20" />
                            </div>
                        </div>
                    </div>

                    <div id="employee" className="pt-20 sm:pt-36 container">
                        <div className="md:flex">
                            <div className="md:w-6/12 md:block hidden">
                                <img src={phoneEmployee} alt="phone" className="" />
                            </div>
                            <div className="w-full md:w-6/12 md:mt-10">
                                <div className="text-3xl sm:text-4xl xl:text-5xl font-semibold font-boing text-ep-black">
                                    Employee benefits
                                </div>
                                <div className="mt-12 space-y-8 md:mr-10">
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Earn as you go - Anyday is payday with Earnipay
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Have a financial safety net to cover expenses and urgent payments
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Avoid unnecessary borrowing - avoid predatory payday loans.
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 text-lg">
                                        <div className="flex-shrink-0 w-6">
                                            <img src={checkedCircle} alt="checkedCircle" className="mt-1" />
                                        </div>
                                        <div>
                                            Access accrued salary on the go via mobile app, web or USSD.
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <Link to="/refer-employer" className="btn btn-ep-blue btn-lg">
                                        Refer your Employer
                                    </Link>
                                </div>
                                <div className="mt-6">
                                    <span className="text-gray-500">
                                        For More Information, Visit the
                                    </span>
                                    <Link to="/faqs" className="text-ep-blue pl-1 hover:underline">FAQs Section</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                
                <div className="relative" style={{"backgroundColor":"#05083F"}}>
                    <div className="relative z-10 container">
                        <div className="py-28 lg:pb-0 lg:flex lg:justify-between">
                            <div className="lg:mt-10">
                                <div className="text-4xl lg:text-5xl text-center lg:text-left mr-0 lg:mr-40 font-semibold text-white" style={{"lineHeight":"1.2"}}>
                                    Download the&nbsp;
                                    <br className="hidden lg:block" />
                                    Earnipay app
                                </div>
                                <div className="flex mt-6 lg:mb-10 justify-center lg:justify-start">
                                    {/* <Link to="https://apps.apple.com/ng/app/earnipay/id1582293975" onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="inline-block mt-2 mr-2 md:mr-4 pl-0" target="_blank" rel="noreferrer"> */}
                                    <Link to={{pathname:"https://apps.apple.com/ng/app/earnipay/id1582293975"}} onClick={(e) => {window.open("https://apps.apple.com/ng/app/earnipay/id1582293975", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block h-8 mt-2">
                                        <img src={appStore} className="cursor-pointer h-12 sm:h-16" alt="apple appstore" />
                                    </Link>
                                    {/* <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="inline-block mt-2 mr-2 md:mr-4 pl-0" target="_blank" rel="noreferrer"> */}
                                    <Link to={{pathname:"https://play.google.com/store/apps/details?id=com.earnipay.earnipay"}} onClick={(e) => {window.open("https://play.google.com/store/apps/details?id=com.earnipay.earnipay", "_blank", "noopener,noreferrer"); e.preventDefault();}} className="inline-block h-8 mt-2">
                                        <img src={playStore} className="cursor-pointer h-12 sm:h-16" alt="google playstore" />
                                    </Link>
                                </div>
                            </div>
                            <img src={phoneDownload} style={{"height":"22rem"}} className="hidden lg:block" alt="phoneDownload" />
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-2-under"></div>
                </div>


                <div className="mt-20 md:mt-24" id="testimonials">
                    <div className="container">
                        <div className="">

                            <div className="flex justify-between">
                                <div className="text-left text-3xl sm:text-4xl xl:text-5xl font-semibold font-boing">
                                    Loved by&nbsp;
                                    <br className="inline-block sm:hidden" />
                                    employee-centered
                                    <br />
                                    organisations
                                </div>

                                <div className="hidden h-24 space-x-4 pt-6 flex justify-between">
                                    <ArrowCircleRight onClick={this.testimonySliderPrev} className="customizeSVG text-ep-blue cursor-pointer" />
                                    <ArrowCircleLeft onClick={this.testimonySliderNext} className="customizeSVG text-gray-300 cursor-pointer" />
                                </div>
                            </div>
                            
                            <div className="pt-10 pb-20 relative">
                                <div className="relative z-10">

                                    <div className="hidden md:block">
                                        <Slide {...slideshowProperties} easing="ease" as="div" className="">

                                            <div className="h-full mt-10 mx-4 pt-14 px-14 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials2} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-lg lg:text-xl font-semibold">
                                                            Solomon Dawudu
                                                        </div>
                                                        <div className="opacity-40 text-black text-base">
                                                            Co Founder of Takeout Media
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-6 leading-6">
                                                    Running a business can be demanding, having motivated employees is paramount to our success and with earnipay’s flexible salary payment solutions, productivity isn’t a demand anymore, it’s a lifestyle.
                                                </div>
                                                <img src={TakeoutMedia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                            <div className="h-full mt-10 mx-4 pt-14 px-14 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials3} alt="Adebowale Banjo" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-lg lg:text-xl font-semibold">
                                                            Adebowale Banjo
                                                        </div>
                                                        <div className="opacity-40 text-black text-base">
                                                            CEO of Venia Group
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-6 leading-6">
                                                    Earnipay's flexible salary has been such a positive impact in our organisation, our employees are able to access their earned salaries at will without any added pressure on our payroll and accounting systems.
                                                </div>
                                                <img src={venia} alt="venia" className="h-16" />
                                            </div>

                                        </Slide>
                                    </div>

                                    <div className="block md:hidden">
                                        <Slide {...slideshowPropertiesMobile} easing="ease">

                                            <div className="m-4 p-6 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials2} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-base font-semibold">
                                                            Solomon Dawudu
                                                        </div>
                                                        <div className="opacity-40 text-black text-sm">
                                                            Co Founder of Takeout Media
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6 pb-2 leading-6">
                                                    Running a business can be demanding, having motivated employees is paramount to our success and with earnipay’s flexible salary payment solutions, productivity isn’t a demand anymore, it’s a lifestyle.
                                                </div>
                                                <img src={TakeoutMedia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                            <div className="m-4 p-6 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={testimonials3} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-base font-semibold">
                                                            Adebowale Banjo
                                                        </div>
                                                        <div className="opacity-40 text-black text-sm">
                                                            CEO of Venia Group
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6 pb-2 leading-6">
                                                    Earnipay's flexible salary has been such a positive impact in our organisation, our employees are able to access their earned salaries at will without any added pressure on our payroll and accounting systems.
                                                </div>
                                                <img src={venia} alt="TakeoutMedia" className="h-16" />
                                            </div>
                                            
                                        </Slide>
                                    </div>
                                    
                                </div>
                                <div className="bg-img-under bg-map-under"></div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="relative" style={{"backgroundColor":"#05083F"}}>
                    <div className="relative z-10 container">
                        <div className="py-20 sm:py-32 md:px-12 px-6 flex items-center text-center flex-col">
                            <div className="text-2xl md:text-4xl xl:text-5xl text-center font-semibold text-white pb-10 font-boing">
                                Join the Earnipay Movement
                            </div>
                            <Link to="/get-started" className="btn btn-ep-blue btn-lg">
                                Get Started For Free
                            </Link>
                        </div>
                    </div>
                    <div className="bg-img-under bg-spiral-2-under"></div>
                </div>


            </div>
        );
    }
}

export default Home;
