import React from 'react';


const XFrameOptions = () => {
    return (
        <div className="w-screen p-2 sm:p-12 lg:p-16 xl:p-20">


            <div className="">
                <div>
                    Website:
                </div>
                <div className="mt-2 w-full h-96 border rounded-md">
                    <iframe src="https://earnipay.com/" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>
            

            <div className="mt-20">
                <div>
                    Employer App:
                </div>
                <div className="mt-2 w-full h-96 border rounded-md">
                    <iframe src="https://employer.earnipay.com" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>



            <div className="mt-20">
                <div>
                    Employee App:
                </div>
                <div className="mt-2 w-full h-96 border rounded-md">
                    <iframe src="https://app.earnipay.com" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>



            <div className="mt-20">
                <div>
                    Onome-Control App:
                </div>
                <div className="mt-2 w-full h-96 border rounded-md">
                    <iframe src="https://onome.earnipay.com" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>



            <div className="mt-20">
                <div>
                    CMS App:
                </div>
                <div className="mt-2 w-full h-96 border rounded-md">
                    <iframe src="https://cms.earnipay.com" title="iframe check" className="w-full h-full"></iframe>
                </div>
            </div>


        </div>
    )
}
 
export default XFrameOptions;