import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo-white.svg";


const BookDemo = () => {
    return (
        <div className="relative">

            <div className="w-full h-16 md:h-20 flex relative z-[3] bg-ep-primary-600 md:bg-transparent">
                <div className="container my-auto">
                    <div className="flex justify-between">
                        <Link to="/" className="h-10 p-1 flex-shrink-0 flex">
                            <img src={logo} alt="Earnipay logo" className="my-auto h-full object-cover" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="md:container relative z-[2] md:flex md:justify-between">
                <div className="w-full md:w-1/2 pt-14 pb-20 md:pr-10 bg-ep-primary-600 md:bg-transparent">
                    <div className="container md:mx-0 md:max-w-none md:px-0">
                        <div className="text-3xl sm:text-4xl lg:text-5xl text-ep-primary-light font-bold font-aeonik">
                            Find out how <br className="hidden xl:block" /> Earnipay can solve <br className="hidden xl:block" /> your Finance and <br className="hidden xl:block" /> People operations <br className="hidden xl:block" /> challenges
                        </div>
                        <div className="mt-10 md:mt-28 text-base">
                            <div className="text-[#A4EDD8]">
                                Don’t want to fill out a form, you can send an email to <br className="hidden sm:block" /> <a href="mailto:sales@earnipay.com" target="_blank" rel="noreferrer" className="text-white hover:underline">sales@earnipay.com</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/2 py-14 md:pl-10">
                
                    <div className="page-title">
                        <div className="font-bold text-2xl sm:text-3xl">
                            Get started with Earnipay <span className="text-ep-primary-600">for free</span>
                        </div>
                        <div>
                            Give your employees the financial benefits they truly deserve.
                            <br />
                            Create an account on Earnipay within minutes.
                        </div>
                    </div>

                    <div className="mt-10 max-w-md mx-auto px-4">
                        <HubspotForm />
                    </div>

                </div>
            </div>

            <div className="absolute z-[1] inset-0 hidden md:flex justify-between">
                <div className="w-1/2 bg-ep-primary-600 bg-waves"></div>
                <div className="w-1/2"></div>
            </div>

        </div>
    )
}


const HubspotForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "4b688edc-d63f-4cec-9778-a8c76205da2d",
                    target: '#hubspotForm'
                });
            }
        });
    
    }, []);
  
    return (
        <div id="hubspotForm"></div>
    )
}

export default BookDemo;
