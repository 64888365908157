import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import thanksImage from "assets/images/icons/thanks-snippet.png";


const AppointmentSuccess = () => {

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'CompleteRegistration');
      };
    };
  }, []);

  return (
    <div className="py-20 flex overflow-hidden text-black">
		<div className="m-auto px-4 text-center max-w-lg overflow-hidden">
			<div className="h-20 sm:h-16 md:h-36">
				<img src={thanksImage} alt="EarniPay" className="h-full object-contain mx-auto" />
			</div>
			<div className="mt-4" style={{"lineHeight": "180%"}}>
				Thank you for booking an appointment. Please check your email for the details of your appointment.
				<br />
				We look forward to speaking with you.
			</div>
			<div className="mt-5 font-semibold">
				Are employee's money worries costing your company?
			</div>
			<Link to={{ pathname: "https://res.cloudinary.com/earnipay/image/upload/v1646139968/Guide_1_-_Employee_Stress_Costing_Business_Money_kuiazt.pdf" }} target="_blank" className="btn btn-block btn-lg btn-ep-blue mt-8 w-full animate hover:scale-110">
				Download this free guide to find out
			</Link>
		</div>
    </div>
  );
}

export default AppointmentSuccess;