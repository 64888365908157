import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import thanksImage from "assets/images/icons/thanks-snippet.png";


const WebinarRedirectPage = () => {
  
    useEffect(() => {
        if (typeof window !== "undefined") {
            if(window.fbq != null) { 
                window.fbq('track', 'Lead')
            };
        };
    }, []);

    return (
        <div className="py-20 flex overflow-hidden text-black">
            <div className="m-auto px-8 text-center max-w-md overflow-hidden">
                
                <div className="h-20 sm:h-16 md:h-36">
                    <img src={thanksImage} alt="EarniPay" className="h-full object-contain mx-auto" />
                </div>
                
                <div className="mt-4">
                    Thank you for registering for the webinar, the details have been sent to you.
                </div>

                <Link to="/" className="btn btn-block btn-lg btn-ep-blue mt-8 w-full animate hover:scale-110">
                    Go to homepage
                </Link>

            </div>
        </div>
    );
}

export default WebinarRedirectPage;