
import React, {useEffect} from "react";

const WebinarTwoForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js-eu1.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "1af92e49-2545-4110-8c4d-2df50f32814f",
                    target: '#hubspotForm'
                });
            }
        });
    
    }, []);

return (
    <div>
        <div id="hubspotForm"></div>
    </div>
)};

export default WebinarTwoForm;
