import React, { useEffect } from 'react';
import webinarsix from "assets/images/webinar-marketing/webinarsix.jpg";

const WebinarSixForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js-eu1.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        console.log(document.body)
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: '25087743',
                    formId: "dbd0f722-a819-47a4-94c1-f820b0b04bd8",
                    target: '#hubspotForm'
                });
            }
        });

    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    )
};

const WebinarSix = () => {
    return (

        <div className=" bg-white overflow-x-hidden">
            <div>
                <div className="container marketing-container">
                    <div className='lg:pl-2 sm:pl-0 lg:flex justify-between align-middle'>
                        <div className="pt-2 sm:pb-3 md:pb-6 lg:pb-10  lg:w-3/5 bg-white">
                            <div className="mb-4 pt-4">
                                <h5 className='pb-3 pt-2 font-semibold font-sans text-base'>WEBINAR SERIES</h5>

                                <div className="pb-2 lg:pr-10 sm:pr-0 md:pr-4 text-lg md:text-3xl lg:text-3xl xl:text-3xl font-recoleta font-semibold">Helping employees thrive through disruptions</div>
                            </div>
                            <div className='mx-auto caption rounded'>
                                <div className='pb-3 lg:pr-14 rounded'>
                                    <img src={webinarsix} alt="mareting " className='w-full h-full object-contain rounded' />
                                </div>
                                <div className=" pt-2 pb-6 sm:pr-2 lg:pr-14 font-work-sans text-sm md:text-base lg:text-base ">

                                    <div className='py-3'>
                                        In the past few months we have seen massive disruptions in the global economy. From
                                        high inflation rates to plunges in the stock market, rising interest rates, and layoffs across top industries
                                        <br /> <br />
                                        These disruptions have left both companies and employees worried and when employees feel helpless over their futures, productivity, innovation, creativity and collaboration take a hit.
                                        <br /> <br />
                                        How do we mitigate this? <br />
                                        Join <span className='font-semibold'>Ginika Okoro,</span> Wellbeing Manager for Africa at Unilever, in this Earnipay webinar series as she shares on<span className='font-normal'> “helping employees thrive through disruptions".</span>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='py-8 lg:w-2/5 rounded' >
                            <div className="font-work-sans px-8  py-6" style={{ "minHeight": "500px", "border": "1px solid #9CA3AF" }}>
                                <div className="pb-8 pt-4 text-base md:text-lg lg:text-3xl xl:text-3xl font-recoleta font-semibold">Attend our free webinar</div>
                                <WebinarSixForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default WebinarSix;

