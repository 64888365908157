import React from "react";
import { Link } from "react-router-dom";
import errorIcon from "assets/images/icons/error.svg";

const Error404 = () => {
    return (
        <div className="my-20 flex overflow-hidden">
            <div className="m-auto px-8 text-center max-w-md">
                
                <div className="h-24 sm:h-32 md:h-56">
                    <img src={errorIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                </div>
                <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                    {/* 404 Error */}
                    Page not found
                </div>
                <div className="mt-4 text-gray-500">
                    Oops! It seems like the page you are looking for is not available! Please try again with another page or go back to home.
                </div>

                <Link to="/" className="mt-8 btn btn-block btn-lg btn-ep-blue">
                    Go to homepage
                </Link>

            </div>
        </div>
    )
}

export default Error404;
