import React from "react";
import { ReactComponent as AppleIcon } from "assets/images/apple-logo.svg";
import { ReactComponent as GooglePlayIcon } from "assets/images/google-playstore-logo.svg";

export const PlayStoreButtons = () => {
    return (
        <div className="flex items-center">
            <a
                href="https://apps.apple.com/ng/app/earnipay/id1582293975"
                className="playstore-btn"
            >
                <AppleIcon className="playstore-btn-icon" />
                <div className="playstore-text-wrapper">
                    <span className="playstore-btn-sub-text">
                        Download on the
                    </span>
                    <p className="playstore-btn-text">App Store</p>
                </div>
            </a>
            <a
                href="https://play.google.com/store/apps/details?id=com.earnipay.earnipay"
                className="playstore-btn google-playstore-btn"
            >
                <GooglePlayIcon className="playstore-btn-icon" />
                <div className="playstore-text-wrapper">
                    <span className="playstore-btn-sub-text google-playstore-btn-sub-text">GET IT ON</span>
                    <p className="playstore-btn-text google-playstore-btn-text">Google Play</p>
                </div>
            </a>
        </div>
    );
};
