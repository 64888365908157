import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div>
      <div className="pt-12 pb-20 px-4">
        <div>
          <div className="my-6 font-semibold text-2xl md:text-3xl text-center">
            Cookie Policy
          </div>
          <div className="terms md:px-32">
            <ol className="parent-ol">
              <div className="terms-title">INTRODUCTION</div>
              <div>
                Earnipay (referred to herein as "us", "we", or "earnipay.com/")
                may utilize cookies, web beacons, tracking pixels, and other
                tracking technologies when you access our website, encompassing
                any other media format, media channel, mobile website, or mobile
                application associated with it (collectively, the "Site"), to
                enhance your experience and tailor the Site to your preferences.
                <br />
                We retain the authority to amend this Cookie Notice at our
                discretion and without prior notice. Any alterations will be
                communicated by updating the "Last Updated" date of this Cookie
                Notice. Such modifications will take effect immediately upon the
                updated Cookie Notice being posted on the Site, and you hereby
                waive the right to receive individual notifications for each
                change or modification.
                <br />
                We encourage you to periodically review this Cookie Notice to
                stay informed about any updates. Your continued use of the Site
                after the posting date of any revised Cookie Notice implies your
                awareness of, agreement to, and acceptance of the changes
                therein.
              </div>

              <div className="terms-title">USE OF COOKIES</div>

              <div>
                A “cookie” is a string of information which assigns you a unique
                identifier that we store on your computer. Your browser then
                provides that unique identifier to use each time you submit a
                query to the Site. We use cookies on the Site to, among other
                things, keep track of services you have used, record
                registration information, record your user preferences, keep you
                logged into the Site, facilitate purchase procedures, and track
                the pages you visit. Cookies help us understand how the Site is
                being used and improve your user experience.
                <ol className="parent-ol">
                  <li className="terms-title">OUR COOKIES</li>
                  Our cookies are “first-party cookies”, and can be either
                  permanent or temporary. These are necessary cookies, without
                  which the Site won't work properly or be able to provide
                  certain features and functionalities. Some of these may be
                  manually disabled in your browser, but may affect the
                  functionality of the Site.
                </ol>
              </div>

              <div className="terms-title">CONTROL OF COOKIES</div>
              <div>
                Most browsers are set to accept cookies by default. However, you
                can remove or reject cookies in your browser’s settings. Please
                be aware that such action could affect the availability and
                functionality of the Site
              </div>

              <div className="terms-title">PRIVACY POLICY</div>
              <div>
                For more information about how we use information collected by
                cookies and other tracking technologies, please refer to our
                Privacy Policy. This Cookie Notice is part of and is
                incorporated into our Privacy Policy. By using the Site, you
                agree to be bound by this Cookie Notice and our Privacy Notice.
              </div>

              <div className="terms-title">CONTACT US</div>
              <div>
                All access requests, questions, comments, complaints and other
                requests regarding the cookies notice should be sent to&nbsp;
                <Link
                  to={{ pathname: "mailto:hello@earnipay.com" }}
                  className="text-ep-primary-400 hover:underline"
                  onClick={(e) => {
                    window.location = e.target.getAttribute("href");
                    e.preventDefault();
                  }}
                >
                  hello@earnipay.com
                </Link>
                <br />
                We may request additional details from you regarding your
                complaints and keep records of your requests and resolution.
              </div>

              <p className="mb-0 mt-14 text-gray-500 text-sm italic">
                Last Updated on{" "}
                <span className="font-medium">12th March, 2024.</span>
              </p>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
