import React, { useState, useEffect } from "react";

export const TextChanger = ({ textList, textClassName }) => {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % textList.length);
		}, 2000);

		return () => clearInterval(interval);
	}, [textList.length]);

	return (
		<div className="text-container">
			<span
				key={index}
				className={`animated-text ${
					textClassName ? textClassName : ""
				}`}
			>
				{textList[index]}
			</span>
		</div>
	);
};
