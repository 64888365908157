import CookieConsent from 'react-cookie-consent'
import cookie from "assets/images/icons/cookie-icon.png"
import { useHistory } from 'react-router-dom'


const CookieConsentModal = () => {
    const history = useHistory();

    
    return (
        <CookieConsent
            overlay
            location="bottom"
            cookieName="EARNIPAY_WEBSITE_COOKIE_CONSENT"
            buttonText="Accept Cookies"
            enableDeclineButton
            declineButtonText="Decline Cookies"
            style={{
                background: "#FFF",
                width: "420px",
                marginLeft: "auto",
                position: "absolute",
                right: 0,
                borderRadius: "10px",
                paddingBottom: "10px",
                paddingLeft: "30px",
            }}
            buttonStyle={{ 
                backgroundColor: "#00535C",
                color: "#FFF",
                borderRadius: "3px",
                fontSize: "14px",
            }}
            declineButtonStyle={{
                color: "#00535C",
                border: "1px solid #00535C",
                backgroundColor: "#FFF",
                borderRadius: "3px",
                fontSize: "14px",
            }}
            buttonWrapperClasses="!space-x-0"
            expires={999}
            onAccept={() => window.location.reload()}
            onDecline={() => window.location.reload()}>
                <div className="w-full">
                    <div className="flex items-center">
                        <img src={cookie} width={50} alt="cookie" />
                        <p className="mb-0 font-semibold text-black ml-2">Cookie Settings</p>
                    </div>
                    <div className="mt-5 text-gray-500 text-sm">
                        We use cookies to provide you with the best possible experience.
                        They allow us to analyse user behaviour in order to constantly
                        improve the website for you.&nbsp;
                        
                        <span onClick={() => history.push("/cookie-policy")} className="text-ep-primary-600 underline whitespace-nowrap cursor-pointer font-medium">
                            Cookie Policy
                        </span>
                    </div>
                </div>
		</CookieConsent>
    )
}

export default CookieConsentModal